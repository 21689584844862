<template>
  <footer class="p-2 text-muted text-center text-small fixed-bottom bg-dark">
    <p class="mb-1">
      <i class="fas fa-crosshairs"></i>
      <span class="p-3" @click="gohome">
        AIM Account ({{ this.version }})
      </span>
      :
      <span class="p-3" @click="goabout"> About Me </span>
      :
      <span class="p-3" @click="gopolicy"> Policy Privacy </span>
      <!-- <button @click="gopolicy" type="button" class="btn btn-outline-dark">
      Privacy Policy
    </button> -->
    </p>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    gohome() {
      this.$router.push({ name: "Home" });
    },
    gopolicy() {
      this.$router.push({ name: "Policy" });
    },
    goabout() {
      this.$router.push({ name: "About" });
    },
  },
  mounted() {
    this.version = process.env.VUE_APP_VERSION;
    // console.log(process.env.VUE_APP_VERSION);
  },
  data() {
    return {
      version: "",
    };
  },
};
</script>

<style scoped></style>
