<template>
  <div>
    <Navbar />
    <div class="container-fluid">
      <div class="margin-top-100">
        <div class="row mb-3">
          <h4 class="mb-3">
            ข้อมูลการติดต่อ
          </h4>
          <div class="col-md-4">
            <label class="form-label mt-2">คำนำหน้าชื่อ (Title Name)</label>
            <input
              v-model="
                this.$store.getters.get_setting_state.setting.aim_address
                  .titlename
              "
              type="text"
              class="form-control"
              ref="inputTitlename"
              placeholder="Title Name"
              required
            />
          </div>
          <div class="col-md-4">
            <label class="form-label mt-2">ชื่อ (First Name)</label>
            <input
              v-model="
                this.$store.getters.get_setting_state.setting.aim_address
                  .firstname
              "
              type="text"
              class="form-control"
              ref="inputFirstname"
              placeholder="First Name"
              required
            />
          </div>
          <div class="col-md-4">
            <label class="form-label mt-2">นามสกุล (Last Name)</label>
            <input
              v-model="
                this.$store.getters.get_setting_state.setting.aim_address
                  .lastname
              "
              type="text"
              class="form-control"
              ref="inputLastname"
              placeholder="Last Name"
              required
            />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-4">
            <label class="form-label mt-2">เลขที่บ้าน (Home Number)</label>
            <input
              v-model="
                this.$store.getters.get_setting_state.setting.aim_address.number
              "
              type="text"
              class="form-control"
              ref="number"
              placeholder="ตัวอย่าง 18/6"
              required
            />
          </div>
          <div class="col-md-4">
            <label class="form-label mt-2">หมู่ / ตรอก / ซอย / หมู่บ้าน</label>
            <input
              v-model="
                this.$store.getters.get_setting_state.setting.aim_address.detail
              "
              type="text"
              class="form-control"
              ref="detail"
              placeholder="ตัวอย่าง หมู่ 1 ซอย 8 หมู่บ้านเทพราช"
            />
          </div>
          <div class="col-md-4">
            <label class="form-label mt-2">ถนน (Road)</label>
            <input
              v-model="
                this.$store.getters.get_setting_state.setting.aim_address.road
              "
              type="text"
              class="form-control"
              ref="detail"
              placeholder="ตัวอย่าง ถนน ราเมศวร"
            />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-4">
            <label class="form-label mt-2">ตำบล (subdistrict)</label>
            <input
              v-model="
                this.$store.getters.get_setting_state.setting.aim_address
                  .subdistrict
              "
              type="text"
              class="form-control"
              ref="subdistrict"
              placeholder="ตัวอย่าง ตำบล เขาสามยอด"
            />
          </div>
          <div class="col-md-4">
            <label class="form-label mt-2">อำเภอ (district)</label>
            <input
              v-model="
                this.$store.getters.get_setting_state.setting.aim_address
                  .district
              "
              type="text"
              class="form-control"
              ref="district"
              placeholder="ตัวอย่าง อำเภอ เมือง"
            />
          </div>
          <div class="col-md-4">
            <label class="form-label mt-2">จังหวัด (province)</label>
            <input
              v-model="
                this.$store.getters.get_setting_state.setting.aim_address
                  .province
              "
              type="text"
              class="form-control"
              ref="province"
              placeholder="ตัวอย่าง จังหวัด พิษณุโลก"
            />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-4">
            <label class="form-label mt-2">รหัสไปรษณีย์ (zipcode)</label>
            <input
              v-model="
                this.$store.getters.get_setting_state.setting.aim_address
                  .zipcode
              "
              type="text"
              class="form-control"
              ref="zipcode"
              placeholder="ตัวอย่าง 15000"
            />
          </div>
        </div>
        <hr />
        <div class="row mb-3">
          <div class="col-md-4">
            <label class="form-label mt-2"
              ><i class="fas fa-phone"></i> โทรศัพท์บ้าน (telephone)</label
            >
            <input
              v-model="
                this.$store.getters.get_setting_state.setting.aim_social
                  .telephone
              "
              type="text"
              class="form-control"
              ref="telephone"
              placeholder="ตัวอย่าง 036423032"
              required
            />
          </div>
          <div class="col-md-4">
            <label class="form-label mt-2"
              ><i class="fas fa-mobile-alt"></i> โทรศัพท์มือถือ
              (mobilephone)</label
            >
            <input
              v-model="
                this.$store.getters.get_setting_state.setting.aim_social
                  .mobilephone
              "
              type="text"
              class="form-control"
              ref="mobilephone"
              placeholder="ตัวอย่าง 0805151651"
              required
            />
          </div>
          <div class="col-md-4">
            <label class="form-label mt-2"
              ><i class="fas fa-fax"></i> แฟกซ์ (fax)</label
            >
            <input
              v-model="
                this.$store.getters.get_setting_state.setting.aim_social
                  .faxphone
              "
              type="text"
              class="form-control"
              ref="faxphone"
              placeholder="ตัวอย่าง 0805151651"
              required
            />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-4">
            <label class="form-label mt-2"
              ><i class="fab fa-facebook-square"></i> เฟสบุ๊ค (facebook)</label
            >
            <input
              v-model="
                this.$store.getters.get_setting_state.setting.aim_social
                  .facebook
              "
              type="text"
              class="form-control"
              ref="facebook"
              placeholder="ตัวอย่าง Morakot Thongprom"
              required
            />
          </div>
          <div class="col-md-4">
            <label class="form-label mt-2"
              ><i class="fab fa-youtube"></i> ยูทูป (youtube)</label
            >
            <input
              v-model="
                this.$store.getters.get_setting_state.setting.aim_social.youtube
              "
              type="text"
              class="form-control"
              ref="youtube"
              placeholder="ตัวอย่าง tonmorakot"
              required
            />
          </div>
          <div class="col-md-4">
            <label class="form-label mt-2"
              ><i class="fab fa-instagram"></i> อินตราแกรม (instagram)</label
            >
            <input
              v-model="
                this.$store.getters.get_setting_state.setting.aim_social
                  .instagram
              "
              type="text"
              class="form-control"
              ref="instagram"
              placeholder="ตัวอย่าง tonmorakot"
              required
            />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-4">
            <label class="form-label mt-2"
              ><i class="fab fa-twitter"></i> ทวิตเตอร์ (Twitter)</label
            >
            <input
              v-model="
                this.$store.getters.get_setting_state.setting.aim_social.twitter
              "
              type="text"
              class="form-control"
              ref="twitter"
              placeholder="ตัวอย่าง @tonmorakot"
              required
            />
          </div>
          <div class="col-md-4">
            <label class="form-label mt-2"
              ><i class="fab fa-line"></i> ไลน์ (Line ID)</label
            >
            <input
              v-model="
                this.$store.getters.get_setting_state.setting.aim_social.line
              "
              type="text"
              class="form-control"
              ref="line"
              placeholder="ตัวอย่าง ton_morakot"
              required
            />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-4">
            <button @click="Edit" class="btn btn-primary btn-width100 mt-2" type="button">
              Save (บันทึกข้อมูล)
            </button>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "Setting",
  components: {
    Navbar,
    Footer,
  },
  mounted() {
    // console.log("Setting mounted");
    if (!this.$store.getters.get_setting_state.data) {
      this.$store.dispatch("EditSetting");
    }
  },
  methods: {
    Edit() {
      // console.log(this.$store.getters.get_setting_state)
      this.$store.dispatch("Edit");
    }
  }
};
</script>
