import axios from "axios";
import Swal from "sweetalert2";
import bcrypt from "bcryptjs";

const user = {
  state: {
    data: null,
  },
  mutations: {
    set_user(state, payload) {
      state.data = payload;
    },
  },
  actions: {
    async LoadUsers({ commit }) {
      const token = localStorage.getItem("tokenId");
      const res = await axios.post(
        `${process.env.VUE_APP_BACKEND}/mysqlapi/all`,
        {
          token: token,
        }
      );
      commit("set_user", res.data);
    },
    async EditUser({ dispatch }, { id, password }) {
      Swal.fire({
        allowEscapeKey: true,
        showConfirmButton: false,
        allowOutsideClick: false,
        title: "Loading.....",
        text: "กรุณารอสักครู่",
      });

      const token = localStorage.getItem("tokenId");
      // console.log(password)
      bcrypt.genSalt(11, function(err, salt) {
        // Store hash in your password DB.
        bcrypt.hash(password, salt, function(err, hash) {
          // console.log(hash)
          axios
            .post(`${process.env.VUE_APP_BACKEND}/mysqlapi/update`, {
              token: token,
              id: id,
              password: hash,
            })
            .then(function(res) {
              if (res.data.message === 1) {
                Swal.fire({
                  title: "สำเร็จ",
                  text: "อัพเดตข้อมูลผู้ใช้เรียบร้อย",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
                dispatch("LoadUsers");
              }
            });
        });
      });
    },
    async EditTierUser({ dispatch }, { id, tier }) {
      Swal.fire({
        allowEscapeKey: true,
        showConfirmButton: false,
        allowOutsideClick: false,
        title: "Loading.....",
        text: "กรุณารอสักครู่",
      });

      const token = localStorage.getItem("tokenId");
      // console.log(password)
      axios
        .post(`${process.env.VUE_APP_BACKEND}/mysqlapi/updatetier`, {
          token: token,
          id: id,
          tier: tier,
        })
        .then(function(res) {
          if (res.data.message === 1) {
            Swal.fire({
              title: "สำเร็จ",
              text: "อัพเดตข้อมูลผู้ใช้เรียบร้อย",
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
            dispatch("LoadUsers");
          }
        });
    },
    async DelUser({ dispatch }, id) {
      Swal.fire({
        allowEscapeKey: true,
        showConfirmButton: false,
        allowOutsideClick: false,
        title: "Loading.....",
        text: "กรุณารอสักครู่",
      });

      const token = localStorage.getItem("tokenId");

      let check = await axios.post(
        `${process.env.VUE_APP_BACKEND}/mysqlapi/user`,
        {
          token: token,
          id: id,
        }
      );

      if (check.data.message === 1) {
        Swal.fire({
          title: "สำเร็จ",
          text: "ลบข้อมูลผู้ใช้เรียบร้อย",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch("LoadUsers");
      }
    },
  },
  getters: {
    get_user(state) {
      return state.data;
    },
  },
  modules: {},
};

export default user;
