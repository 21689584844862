<template>
  <div>
    <span class="text-white px-3 h5">{{
      this.$store.getters.get_login_email
    }}</span>
    <button @click="logout" class="btn btn-danger" type="submit">
      Logout
    </button>
  </div>
</template>

<script>
export default {
  name: "Logout",
  methods: {
    async logout() {
      this.$store.commit("Logout");
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style scoped></style>
