<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4">
        <button @click="loadPointsData" class="btn btn-warning mt-3">
          Load Data Point
        </button>
      </div>
      <div class="col-md-4">
        <button
          @click="loadExcel"
          :disabled="disable"
          class="btn btn-primary mt-3"
        >
          Export Excel Point
        </button>
      </div>
      <div class="col-md-4">
        <button
          @click="updatepoints"
          :disabled="disable"
          class="btn btn-success mt-3"
        >
          Update Points
        </button>
      </div>
    </div>

    <!-- <div class="row mt-3">
      <div class="col-md-12">
        เลขที่บิลแต้มสุดท้าย + 1 {{ lastDocnoPlusone }}
      </div>
    </div> -->
    <hr />

    <table class="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">docno</th>
          <th scope="col">customerid</th>
          <th scope="col">point</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(res, index) in result" :key="index">
          <td>{{ res.no }}</td>
          <td>{{ res.docno }}</td>
          <td>{{ res.customerid }}</td>
          <td>{{ res.point }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import XLSX from "xlsx";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      result: null,
      disable: true,
      // lastDocnoPlusone: null,
    };
  },
  async mounted() {},
  methods: {
    async loadPointsData() {
      this.result = null;
      const res = await axios.post(`http://localhost:3000/api/all/points`);
      this.result = await res.data;
      this.disable = await false;
      // console.log(this.result)
      // this.lastDocnoPlusone =
      //   (await parseInt(this.result[this.result.length - 1].docno.substr(3))) +
      //   1;
      // console.log(parseInt(this.result[this.result.length - 1].docno.substr(3)) + 1);
      await Swal.fire({
        title: "สำเร็จ",
        text: "โหลดข้อมูลเรียบร้อยแล้ว",
        icon: "success",
        showConfirmButton: false,
        timer: 3000,
      });
    },
    async loadExcel() {
      //  console.log(this.result);
      const dataWS = await XLSX.utils.json_to_sheet(this.result);
      const wb = await XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "points64_full.xlsx");
    },
    async updatepoints() {
      // console.log(this.result);

      Swal.fire({
        title: "คุณต้องการอัพเดตแต้มหรือไม่ ?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: `ตกลง`,
        denyButtonText: `ยกเลิก`,
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          const res = await axios.post(
            `http://localhost:3000/api/all/updatepoints`,
            this.result
          );

          // console.log(res.data.message);

          if (res.data.message === "ok") {
            await Swal.fire({
              title: "สำเร็จ",
              text: "อัพเดตเรียบร้อยแล้ว",
              icon: "success",
              showConfirmButton: false,
              timer: 3000,
            });
          }
        } else if (result.isDenied) {
          Swal.fire("ยกเลิก", "", "info");
        }
      });
    },
  },
};
</script>

<style></style>
