<template>
  <div>
    <Navbar />
    <div class="container-fluid">
      <div class="margin-top-100 p-3">
        <h1>ความจำ</h1>
        <hr />
        <div class="row">
          <div class="col-md-3">
            <div class="mb-3">
              <label class="form-label">หัวข้อที่ต้องการจำ</label>
              <input
                v-model="remember_name"
                v-on:keyup.enter="save"
                ref="remember_name"
                type="text"
                class="form-control"
                placeholder="กรอกหัวข้อที่ต้องการจำ"
              />
            </div>
            <div class="mb-3">
              <div class="d-grid gap-2 d-md-block">
                <button class="btn btn-primary" @click="save" type="button">
                  บันทึก
                </button>
                <span class="p-2"></span>
                <button class="btn btn-danger" @click="reset" type="button">
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>

          <div class="col-md-9">
            <div
              class="card mb-3"
              v-for="(data, index) in this.$store.getters.get_remember_data"
              :key="index"
            >
              <div class="card-header"><h3>{{ data.remember_name }} <span @click="c2(data._id, data.remember_name)" class="btn btn-warning red_x">C</span></h3></div>
              <div class="card-body">

                <div class="card mb-3">
                  <div class="card-body">
                    <h4>
                      <span v-for="(detail, index) in data.remember_detail"
                  :key="index">
                        <i class="far fa-arrow-alt-circle-right text-primary"></i> &nbsp;{{ detail.hint }}&nbsp;
                      </span>
                    </h4>
                  </div>
                </div>
                
                <!-- <h5 class="card-title">Special title treatment</h5> -->
                <p class="card-text">
                  <ol>
                    <li v-for="(detail, index) in data.remember_detail"
              :key="index" >
                      <span v-if="detail.show">{{ detail.data }}</span>
                      <span @click="o(data._id, detail._id, index)" class="btn btn-info blue_o">O</span>
                      <span>( {{ detail.hint }} )</span>
                      <span @click="c(data._id, detail._id)" class="btn btn-warning red_x">C</span>
                      <span @click="x(data._id, detail._id)" class="btn btn-danger red_x">X</span>
                    </li>
                  </ol>
                </p>
                  <button @click="add(data._id)" class="btn btn-success">เพิ่มข้อมูล</button>
                  <span class="p-2"></span>
                  <!-- <button class="btn btn-primary">จำข้อมูล</button>
                  <span class="p-2"></span> -->
                  <button @click="del(data._id)" class="btn btn-danger">ลบข้อมูล</button>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-md-3">
            <div class="mb-3">
              <label class="form-label">หุ้น</label>
              <input
                v-model="stock"
                ref="stock"
                type="text"
                class="form-control"
                placeholder="กรอกชื่อหุ้น"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">หัวข้อ</label>
              <input
                v-model="title"
                ref="title"
                type="text"
                class="form-control"
                placeholder="กรอกหัวข้อ"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">ข้อความ</label>
              <textarea
                ref="detail"
                v-model="detail"
                class="form-control"
                rows="3"
                placeholder="กรอกข้อความ"
              ></textarea>
            </div>
            <div class="mb-3">
              <div class="d-grid gap-2 d-md-block">
                <button class="btn btn-primary" @click="save" type="button">
                  บันทึก
                </button>
                <span class="p-2"></span>
                <button class="btn btn-danger" @click="reset" type="button">
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="table-responsive">
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th scope="col" class="text-center">หุ้น</th>
                    <th scope="col" class="text-center">หัวข้อ</th>
                    <th scope="col" class="text-center">รายละเอียด</th>
                    <th scope="col" class="text-center">เวลาบันทึก</th>
                    <th scope="col" class="text-center">จัดการ</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(data, index) in this.$store.getters
                      .get_stock_note_data"
                    :key="index"
                  >
                    <td class="text-center">
                      <a href="#" @click="stock_search(data.stock)">
                        {{ data.stock }}
                      </a>
                    </td>
                    <td class="text-center">
                      {{ data.title }}
                    </td>
                    <td>
                      {{ data.detail }}
                    </td>
                    <td class="text-center">
                      {{ data.timesave }}
                    </td>
                    <td class="text-center">
                      <button @click="del(data._id)" class="btn btn-danger">
                        ลบ
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div> -->

        <!-- <hr />
        <div>
          {{ this.$store.getters.get_stock_note_data }}
        </div> -->
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../components/Navbar.vue";
import Footer from "../../components/Footer.vue";

import Swal from "sweetalert2";
// import moment from "moment";

export default {
  name: "Remember",
  components: {
    Navbar,
    Footer,
  },
  mounted() {
    this.$store.dispatch("load_remember_data");
    // this.stock = this.$store.getters.get_stock_name_data;
    this.$refs.remember_name.focus();
  },
  data() {
    return {
      remember_name: "",
      remember_add: "",
      remember_hint: "",
      // stock: "",
      // title: "",
      // detail: "",
    };
  },
  methods: {
    // stock_search(s) {
    //   this.$router.push({ name: "Stock" });
    //   this.$store.dispatch("reset_stock_state");
    //   this.$store.dispatch("load_stock_company_profile", s);
    //   this.$store.dispatch("load_stock_name_data", s);
    //   this.$store.dispatch("load_stock_quote_data", s);
    //   this.$store.dispatch("load_stock_rightsandbenefit_data", s);
    //   this.$store.dispatch("load_stock_historical_data", s);
    //   this.$store.dispatch("load_stock_financial_data", s);
    //   this.$store.dispatch("load_stock_data_success_data");
    // },
    save() {
      // moment.locale("th");
      // let date = moment().add(543, "year").format("LLLL");
      if (this.remember_name.trim() === "") {
        Swal.fire({
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          icon: "error",
          timer: 1200,
          showConfirmButton: false,
        });
        setTimeout(() => {
          if (this.remember_name.trim() === "") {
            this.$refs.remember_name.focus();
          }
        }, 1500);
      } else {
        Swal.fire({
          title: "คุณต้องการบันทึกความจำนี้",
          text: "บันทึกข้อมูล",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "บันทึกข้อมูล",
          cancelButtonText: "ยกเลิก",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store.dispatch("load_remember_save_data", {
              remember_name: this.remember_name.trim(),
            });
            this.remember_name = "";
          }
        });
      }
      // console.log(data);
    },
    del(id) {
      // console.log(id);
      Swal.fire({
        title: "คุณต้องการลบข้อมูล",
        text: "ลบข้อมูล",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "ใช่, ลบข้อมูล",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("load_remember_del_data", id);
        }
      });
    },
    add(id) {
      Swal.fire({
        input: "text",
        inputLabel: "เพิ่มข้อมูลความจำ",
        inputPlaceholder: "กรอกข้อมูลความจำ",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "เพิ่มข้อมูล",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          if (result.value.trim() !== "") {
            // console.log(id)
            // console.log(result.value.trim())

            this.remember_add = result.value.trim();

            Swal.fire({
              input: "text",
              inputLabel: "เพิ่มภาพจำ",
              inputPlaceholder: "กรอกภาพจำ",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "เพิ่มข้อมูล",
              cancelButtonColor: "#d33",
              cancelButtonText: "ยกเลิก",
            }).then((result) => {
              if (result.isConfirmed) {
                if (result.value.trim() !== "") {
                  // console.log(id)
                  // console.log(result.value.trim())

                  this.remember_hint = result.value.trim();

                  this.$store.dispatch("load_remember_add_data", {
                    id: id,
                    remember_detail: this.remember_add,
                    remember_hint: this.remember_hint,
                  });
                } else {
                  Swal.fire("คำเตือน", "กรุณากรอกข้อมูลก่อนกดเพิ่ม", "error");
                }
                // this.$store.dispatch("load_remember_add_data", data);
              }
            });
          } else {
            Swal.fire("คำเตือน", "กรุณากรอกข้อมูลก่อนกดเพิ่ม", "error");
          }
          // this.$store.dispatch("load_remember_add_data", data);
        }
      });
    },
    x(id, id_detail) {
      // console.log(id)
      // console.log(id_detail)
      Swal.fire({
        title: "คุณต้องการลบข้อมูล",
        text: "ลบข้อมูล",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "ใช่, ลบข้อมูล",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("load_remember_del_detail_data", [
            id,
            id_detail,
          ]);
        }
      });
    },
    async c(id, id_detail) {
      // console.log(id)
      // console.log(id)

      await this.$store.dispatch("load_remember_c_detail_data", [
        id,
        id_detail,
      ]);

      // console.log(await this.$store.getters.get_remember_add);
      // console.log(await this.$store.getters.get_remember_hint);

      setTimeout(() => {
        Swal.fire({
          title: "แก้ไขรายละเอียด",
          html: `<input id="swal-input1" class="swal2-input" value="${this.$store.getters.get_remember_add}">
           <input id="swal-input2" class="swal2-input" value="${this.$store.getters.get_remember_hint}">`,
          focusConfirm: false,
          showConfirmButton: true,
          showCancelButton: true,
          preConfirm: () => {
            this.$store.dispatch("load_remember_c_update_detail_data", [
              id,
              id_detail,
              document.getElementById("swal-input1").value,
              document.getElementById("swal-input2").value
            ]);

            // return [
            //   document.getElementById("swal-input1").value,
            //   document.getElementById("swal-input2").value,
            // ];
            
          },
        });

        // if (formValues) {
        //   Swal.fire(JSON.stringify(formValues));
        // }
      }, 500);
    },
    async c2(id, data) {
  
        Swal.fire({
          title: "แก้ไขรายละเอียด",
          html: `<input id="swal-input1" class="swal2-input" value="${data}">`,
          focusConfirm: false,
          showConfirmButton: true,
          showCancelButton: true,
          preConfirm: () => {
            this.$store.dispatch("load_remember_c_update_header_detail_data", [
              id,
              document.getElementById("swal-input1").value,
            ]);

          },
        });

    },
    o(id, id_detail, index) {
      this.$store.dispatch("load_remember_detail_show_data", [
        id,
        id_detail,
        index,
      ]);
    },
    reset() {
      this.remember_name = "";
    },
  },
};
</script>
