import axios from "axios";
import gFY from "../assets/js/getFullYear.js";
import Swal from "sweetalert2";

const stock = {
  state: {
    stock_quote_financial_5_year: [],
    stock_date_xd: "",
    stock_note: null,
    stock_company_profile: [],
    stock_month_thai: "",
    stock_xd_id: 1,
    stock_name: "",
    stock_report: null,
    stock_report_xd: [],
    stock_financial: [],
    stock_choose_buy: 0,
    stock_last_quote: "",
    stock_quote: [],
    stock_quote_detail: {
      Prior: "",
      Open: "",
      High: "",
      Low: "",
      AveragePrice: "",
      Volume: "",
      Value: "",
      ParValue: "",
      Ceiling: "",
      Floor: "",
      Bid: "",
      Offer: "",
    },
    stock_rightsandbenefit: [],
    stock_rightsandbenefit_detail: [],
    stock_rightsandbenefit_summary: [],
    stock_rightsandbenefit_summary_div_avg: 0,
    stock_historical: [],
    stock_historical_detail: [],
    stock_data_success: false,
  },
  mutations: {
    set_reset_stock_report_xd_state(state) {
      state.stock_month_thai = "";
      state.stock_report_xd = [];
    },
    set_reset_stock_state(state) {
      state.stock_quote_financial_5_year = [];
      state.stock_date_xd = "";
      state.stock_note = null;
      state.stock_company_profile = [];
      // state.stock_xd_id = 1
      state.stock_name = "";
      state.stock_report = null;
      // state.stock_report_xd = [];
      state.stock_financial = [];
      state.stock_choose_buy = 0;
      state.stock_last_quote = "";
      state.stock_quote = [];
      state.stock_quote_detail = {
        Prior: "",
        Open: "",
        High: "",
        Low: "",
        AveragePrice: "",
        Volume: "",
        Value: "",
        ParValue: "",
        Ceiling: "",
        Floor: "",
        Bid: "",
        Offer: "",
      };
      state.stock_rightsandbenefit = [];
      state.stock_rightsandbenefit_detail = [];
      state.stock_rightsandbenefit_summary = [];
      state.stock_rightsandbenefit_summary_div_avg = 0;
      state.stock_historical = [];
      state.stock_historical_detail = [];
      state.stock_data_success = false;
    },
    set_stock_company_profile(state, payload) {
      state.stock_company_profile.push(payload);
    },
    set_stock_month_thai(state, month) {
      state.stock_month_thai = month;
    },
    set_stock_xd_id_state(state, id) {
      state.set_stock_xd_id_state = id;
    },
    set_stock_name_state(state, name) {
      state.stock_name = name;
    },
    set_stock_note(state, payload) {
      // console.log(payload)
      state.stock_note = payload;
    },
    set_stock_report_state(state, payload) {
      state.stock_report = payload;
    },
    set_stock_reportXD_state(state, payload) {
      state.stock_report_xd.push(payload);
    },
    set_stock_quote_state(state, payload) {
      if (state.stock_quote.length < 12) {
        state.stock_quote.push(payload.innerHTML.replace("\n", "").trim());

        if (state.stock_quote.length === 1) {
          state.stock_quote_detail.Prior = state.stock_quote[0];
        } else if (state.stock_quote.length === 2) {
          state.stock_quote_detail.Open = state.stock_quote[1];
        } else if (state.stock_quote.length === 3) {
          state.stock_quote_detail.High = state.stock_quote[2];
        } else if (state.stock_quote.length === 4) {
          state.stock_quote_detail.Low = state.stock_quote[3];
        } else if (state.stock_quote.length === 5) {
          state.stock_quote_detail.AveragePrice = state.stock_quote[4];
        } else if (state.stock_quote.length === 6) {
          state.stock_quote_detail.Volume = state.stock_quote[5];
        } else if (state.stock_quote.length === 7) {
          state.stock_quote_detail.Value = state.stock_quote[6];
        } else if (state.stock_quote.length === 8) {
          state.stock_quote_detail.ParValue = state.stock_quote[7];
        } else if (state.stock_quote.length === 9) {
          state.stock_quote_detail.Ceiling = state.stock_quote[8];
        } else if (state.stock_quote.length === 10) {
          state.stock_quote_detail.Floor = state.stock_quote[9];
        } else if (state.stock_quote.length === 11) {
          state.stock_quote_detail.Bid = state.stock_quote[10];
        } else if (state.stock_quote.length === 12) {
          state.stock_quote_detail.Offer = state.stock_quote[11];
        }
      }
    },
    set_stock_rightsandbenefit_state(state, htmlString) {
      let doc = new DOMParser().parseFromString(htmlString.data, "text/html");

      for (const table of doc.getElementsByTagName("table")) {
        let i = 0;
        let x = 9;
        let y = 12;
        let z = 14;
        for (const td of table.rows) {
          for (const value of td.cells) {
            if (i > 8) {
              if (i === x || i === y || i === z) {
                state.stock_rightsandbenefit.push(
                  value.innerHTML.replace("\n", "").trim()
                );

                if (i === x) {
                  x += 9;
                } else if (i === y) {
                  y += 9;
                } else if (i === z) {
                  z += 9;
                }
              }
            }
            i++;
          }
        }
      }

      let rdf_i = 1;
      let rdf_data = [];
      let data_sum = {};
      let year = 0;
      let year_check = 0;

      // console.log(state.stock_rightsandbenefit);

      for (const raw of state.stock_rightsandbenefit) {
        if (rdf_i === 1) {
          state.stock_date_xd = raw;
        }

        rdf_data.push(raw);

        if (rdf_i % 3 === 0) {
          if (rdf_data[0] === "-") {
            continue;
          } else {
            year_check = parseInt(
              rdf_data[0].substring(rdf_data[0].length - 2, rdf_data[0].length)
            );
          }

          if (rdf_data[2] === "-") {
            rdf_data[2] = 0;
          }

          if (rdf_data[2].search(":") > 0) {
            rdf_data[2] = 0;
          }

          if (year !== year_check) {
            data_sum.year = year_check;
            data_sum.div = parseFloat(rdf_data[2]);

            state.stock_rightsandbenefit_summary.push(data_sum);

            data_sum = {};
          } else {
            state.stock_rightsandbenefit_summary[
              state.stock_rightsandbenefit_summary.length - 1
            ].div += parseFloat(rdf_data[2]);
          }

          year = year_check;

          state.stock_rightsandbenefit_detail.push({
            XDate: rdf_data[0],
            PaymentDate: rdf_data[1],
            Dividend: rdf_data[2],
          });

          rdf_data = [];
        }

        rdf_i++;
      }

      // console.log(state.stock_rightsandbenefit_detail)
      // console.log(state.stock_rightsandbenefit_summary);

      for (const data of state.stock_rightsandbenefit_summary) {
        // console.log(data.div);
        state.stock_rightsandbenefit_summary_div_avg += data.div;
      }

      state.stock_rightsandbenefit_summary_div_avg /=
        state.stock_rightsandbenefit_summary.length;

      // console.log(state.stock_rightsandbenefit_summary_div_avg);
    },
    set_stock_historical_state(state, htmlString) {
      let doc = new DOMParser().parseFromString(htmlString.data, "text/html");

      for (const table of doc.getElementsByTagName("table")) {
        let i = 0;
        for (const td of table.rows) {
          let r = [];
          for (const value of td.cells) {
            if (i > 11) {
              r.push(value.innerHTML.replace("\n", "").trim());
            }

            if (i === 17) {
              state.stock_last_quote = r[5];
            }

            if (r.length === 12) {
              state.stock_historical.push(r);
              r = [];
            }
            i++;
          }
        }
      }
    },
    set_stock_financial_state(state, payload) {
      // console.log(payload[1][1][0]);

      for (const key in payload[1][1][0]) {
        if (key > 0) {
          state.stock_quote_financial_5_year.push(
            parseFloat(payload[1][1][0][key])
          );
        }
      }
      state.stock_quote_financial_5_year.reverse();
      // console.log(state.stock_quote_financial_5_year[5] === undefined ? "t" : "f");
      state.stock_financial.push(payload);
    },
    set_stock_data_success_state(state) {
      state.stock_data_success = true;
    },
  },
  actions: {
    reset_stock_state({ commit }) {
      commit("set_reset_stock_state");
    },
    reset_stock_report_xd_state({ commit }) {
      commit("set_reset_stock_report_xd_state");
    },
    load_stock_report_sort({ commit, state }, s) {
      function dynamicSort(property) {
        let sortOrder = 1;
        if (property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
        }
        return function(a, b) {
          /* next line works with strings and numbers,
           * and you may want to customize it to your needs
           */
          let result =
            a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
          return result * sortOrder;
        };
      }

      commit("set_stock_report_state", state.stock_report.sort(dynamicSort(s)));
    },
    load_stock_company_profile({ commit }, symbol) {
      axios
        .post(`${process.env.VUE_APP_BACKEND}/api/stock/companyprofile`, {
          symbol: symbol,
        })
        .then((data) => {
          let doc = new DOMParser().parseFromString(data.data, "text/html");
          // commit("set_stock_company_profile", data.data);
          let d = [];
          for (const table of doc.getElementsByTagName("table")) {
            for (const td of table.rows) {
              for (const value of td.cells) {
                d.push(
                  value.innerText
                    .replace(/\r?\n|\r/g, "")
                    .trim()
                    .split(" ")
                );
              }
            }
          }

          let e = false;
          let d2 = [];
          for (let i = 0; i < d.length; i++) {
            for (let j = 0; j < d[i].length; j++) {
              const element = d[i][j];
              if (element.length > 0) {
                // if (element === "แบบแสดงรายการข้อมูลประจำปี/รายงานประจำปี" || element === "รายงานประจำปี") {
                if (element === "รายละเอียดเกี่ยวกับทุน") {
                  e = true;
                  break;
                }
                if (!e) {
                  d2.push(element);
                }
              }
            }
            if (e) {
              break;
            }
          }

          let check_number = [];
          for (let j = 0; j < d2.length; j++) {
            const element = d2[j];
            if (element === "ชื่อบริษัท") {
              check_number.push(j);
            } else if (element === "ที่อยู่") {
              check_number.push(j);
            } else if (element === "เบอร์โทรศัพท์") {
              check_number.push(j);
            } else if (element === "เบอร์โทรสาร") {
              check_number.push(j);
            } else if (element === "เว็บไซต์") {
              check_number.push(j);
            } else if (element === "ตลาด") {
              check_number.push(j);
            } else if (element === "กลุ่มอุตสาหกรรม") {
              check_number.push(j);
            } else if (element === "หมวดธุรกิจ") {
              check_number.push(j);
            } else if (element === "วันที่เริ่มต้นซื้อขาย") {
              check_number.push(j);
            }
          }

          for (let i = 0; i < check_number.length; i++) {
            commit(
              "set_stock_company_profile",
              d2.slice(check_number[i], check_number[i + 1])
            );
          }
        })
        .catch(() => {});
    },
    load_stock_month_thai({ commit }, month) {
      commit("set_stock_month_thai", month);
    },
    load_stock_xd_id_data({ commit }, id) {
      commit("set_stock_xd_id_state", id);
    },
    load_stock_name_data({ commit }, name) {
      commit("set_stock_name_state", name);
    },
    load_stock_quote_data({ commit }, symbol) {
      axios
        .post(`${process.env.VUE_APP_BACKEND}/api/stock/quote/`, {
          symbol: symbol,
        })
        .then(function(htmlString) {
          let doc = new DOMParser().parseFromString(
            htmlString.data,
            "text/html"
          );

          for (const table of doc.getElementsByTagName("table")) {
            for (const td of table.rows) {
              let i = 0;
              for (const value of td.cells) {
                if (i === 1) {
                  commit("set_stock_quote_state", value);
                }
                i++;
              }
            }
          }
        })
        .catch(function(err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_stock_rightsandbenefit_data({ commit }, symbol) {
      axios
        .post(`${process.env.VUE_APP_BACKEND}/api/stock/rightsandbenefit/`, {
          symbol: symbol,
        })
        .then(function(htmlString) {
          commit("set_stock_rightsandbenefit_state", htmlString);
        })
        .catch(function(err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_stock_historical_data({ commit }, symbol) {
      axios
        .post(`${process.env.VUE_APP_BACKEND}/api/stock/historical/`, {
          symbol: symbol,
        })
        .then(function(htmlString) {
          commit("set_stock_historical_state", htmlString);
        })
        .catch(function(err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_stock_financial_data({ commit }, symbol) {
      axios
        .post(`${process.env.VUE_APP_BACKEND}/api/stock/financial/`, {
          symbol: symbol,
        })
        .then(function(htmlString) {
          let doc = new DOMParser().parseFromString(
            htmlString.data,
            "text/html"
          );

          let r = [];
          let r1 = [];
          let r1_1 = [];
          let r1_2 = [];
          let r1_3 = [];
          let r1_4 = [];
          let r1_5 = [];
          let r1_6 = [];
          let r1_7 = [];
          let r1_8 = [];
          let r1_9 = [];
          let r1_10 = [];
          let r2 = [];
          let r2_1 = [];
          let r2_2 = [];
          let r2_3 = [];
          let r2_4 = [];
          let r2_5 = [];
          let r2_6 = [];
          let r2_7 = [];
          let t1 = [];
          let t2 = [];
          for (const table of doc.getElementsByTagName("table")) {
            for (const td of table.rows) {
              for (const value of td.cells) {
                r.push(value.innerHTML.replace("\n", "").trim());

                if (r.length - 1 === 0) {
                  r1[0] = `งวดงบการเงิน ณ วันที่`;
                } else if (r.length - 1 === 1) {
                  r1[1] = `งบปี ${gFY() - 4} 31/12/25${gFY() - 4}`;
                } else if (r.length - 1 === 2) {
                  r1[2] = `งบปี ${gFY() - 3} 31/12/25${gFY() - 3}`;
                } else if (r.length - 1 === 3) {
                  r1[3] = `งบปี ${gFY() - 2} 31/12/25${gFY() - 2}`;
                } else if (r.length - 1 === 4) {
                  r1[4] = `งบปี ${gFY() - 1} 31/12/25${gFY() - 1}`;
                } else if (r.length - 1 === 6) {
                  // else if ((r.length - 1) === 5) {
                  //   r1[5] = ``;
                  // }
                  r2[0] = `ค่าสถิติสำคัญ ณ วันที่`;
                } else if (r.length - 1 === 7) {
                  r2[1] = r[7].replace("<strong>", "").trim();
                  r2[1] = r2[1].replace("</strong>", "").trim();
                } else if (r.length - 1 === 8) {
                  r2[2] = r[8].replace("<strong>", "").trim();
                  r2[2] = r2[2].replace("</strong>", "").trim();
                } else if (r.length - 1 === 9) {
                  r2[3] = r[9].replace("<strong>", "").trim();
                  r2[3] = r2[3].replace("</strong>", "").trim();
                } else if (r.length - 1 === 10) {
                  r2[4] = r[10].replace("<strong>", "").trim();
                  r2[4] = r2[4].replace("</strong>", "").trim();
                } else if (r.length - 1 === 11) {
                  r2[5] = r[11].replace("<strong>", "").trim();
                  r2[5] = r2[5].replace("</strong>", "").trim();
                } else if (r.length - 1 === 14) {
                  // if ((r.length - 1) === 12) {
                  //   r1[12] = `บัญชีทางการเงินที่สำคัญ`;
                  // } else if ((r.length - 1) === 13) {
                  //   r[13] = ``;
                  // } else
                  r1_1[0] = `สินทรัพย์รวม`;
                } else if (r.length - 1 === 15) {
                  r1_1[1] = r[15].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 16) {
                  r1_1[2] = r[16].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 17) {
                  r1_1[3] = r[17].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 18) {
                  r1_1[4] = r[18].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 20) {
                  // else if ((r.length - 1) === 19) {
                  //   r[19] = ``;
                  // }
                  r1_2[0] = `หนี้สินรวม`;
                } else if (r.length - 1 === 21) {
                  r1_2[1] = r[21].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 22) {
                  r1_2[2] = r[22].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 23) {
                  r1_2[3] = r[23].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 24) {
                  r1_2[4] = r[24].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 26) {
                  // else if ((r.length - 1) === 25) {
                  //   r1_1[25] = ``;
                  // }
                  r1_3[0] = `ส่วนของผู้ถือหุ้น`;
                } else if (r.length - 1 === 27) {
                  r1_3[1] = r[27].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 28) {
                  r1_3[2] = r[28].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 29) {
                  r1_3[3] = r[29].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 30) {
                  r1_3[4] = r[30].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 32) {
                  // else if ((r.length - 1) === 31) {
                  //   r[31] = ``;
                  // }
                  r1_4[0] = `มูลค่าหุ้นที่เรียกชำระแล้ว`;
                } else if (r.length - 1 === 33) {
                  r1_4[1] = r[33].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 34) {
                  r1_4[2] = r[34].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 35) {
                  r1_4[3] = r[35].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 36) {
                  r1_4[4] = r[36].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 38) {
                  // else if ((r.length - 1) === 37) {
                  //   r1_1[37] = ``;
                  // }
                  r1_5[0] = `รายได้รวม`;
                } else if (r.length - 1 === 39) {
                  r1_5[1] = r[39].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 40) {
                  r1_5[2] = r[40].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 41) {
                  r1_5[3] = r[41].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 42) {
                  r1_5[4] = r[42].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 44) {
                  // else if ((r.length - 1) === 43) {
                  //   r[43] = ``;
                  // }
                  r1_6[0] = `กำไรสุทธิ`;
                } else if (r.length - 1 === 45) {
                  r1_6[1] = r[45].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 46) {
                  r1_6[2] = r[46].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 47) {
                  r1_6[3] = r[47].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 48) {
                  r1_6[4] = r[48].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 50) {
                  // else if ((r.length - 1) === 49) {
                  //   r[49] = ``;
                  // }
                  r1_7[0] = `กำไรต่อหุ้น (บาท)`;
                } else if (r.length - 1 === 51) {
                  r1_7[1] = r[51].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 52) {
                  r1_7[2] = r[52].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 53) {
                  r1_7[3] = r[53].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 54) {
                  r1_7[4] = r[54].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 58) {
                  // else if ((r.length - 1) === 55) {
                  //   r[55] = ``;
                  // }

                  // else if ((r.length - 1) === 56) {
                  //   r[56] = `อัตราส่วนทางการเงินที่สำคัญ`;
                  // } else if ((r.length - 1) === 57) {
                  //   r[57] = ``;
                  // }
                  r1_8[0] = `ROA(%)`;
                } else if (r.length - 1 === 59) {
                  r1_8[1] = r[59].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 60) {
                  r1_8[2] = r[60].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 61) {
                  r1_8[3] = r[61].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 62) {
                  r1_8[4] = r[62].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 64) {
                  // else if ((r.length - 1) === 63) {
                  //   r[63] = ``;
                  // }
                  r1_9[0] = `ROE(%)`;
                } else if (r.length - 1 === 65) {
                  r1_9[1] = r[65].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 66) {
                  r1_9[2] = r[66].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 67) {
                  r1_9[3] = r[67].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 68) {
                  r1_9[4] = r[68].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 70) {
                  // else if ((r.length - 1) === 69) {
                  //   r[69] = ``;
                  // }
                  r1_10[0] = `อัตรากำไรสุทธิ(%)`;
                } else if (r.length - 1 === 71) {
                  r1_10[1] = r[71].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 72) {
                  r1_10[2] = r[72].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 73) {
                  r1_10[3] = r[73].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 74) {
                  r1_10[4] = r[74].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 76) {
                  // else if ((r.length - 1) === 75) {
                  //   r[75] = ``;
                  // }
                  r2_1[0] = `ราคาล่าสุด(บาท)`;
                } else if (r.length - 1 === 77) {
                  r2_1[1] = r[77].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 78) {
                  r2_1[2] = r[78].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 79) {
                  r2_1[3] = r[79].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 80) {
                  r2_1[4] = r[80].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 81) {
                  r2_1[5] = r[81].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 82) {
                  r2_2[0] = `มูลค่าหลักทรัพย์ตามราคาตลาด`;
                } else if (r.length - 1 === 83) {
                  r2_2[1] = r[83].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 84) {
                  r2_2[2] = r[84].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 85) {
                  r2_2[3] = r[85].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 86) {
                  r2_2[4] = r[86].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 87) {
                  r2_2[5] = r[87].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 88) {
                  r2_3[0] = `วันที่ของงบการเงินที่ใช้คำนวณค่าสถิติ`;
                } else if (r.length - 1 === 89) {
                  r2_3[1] = r[89].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 90) {
                  r2_3[2] = r[90].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 91) {
                  r2_3[3] = r[91].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 92) {
                  r2_3[4] = r[92].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 93) {
                  r2_3[5] = r[93].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 94) {
                  r2_4[0] = `P/E (เท่า)`;
                } else if (r.length - 1 === 95) {
                  r2_4[1] = r[95].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 96) {
                  r2_4[2] = r[96].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 97) {
                  r2_4[3] = r[97].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 98) {
                  r2_4[4] = r[98].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 99) {
                  r2_4[5] = r[99].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 100) {
                  r2_5[0] = `P/BV (เท่า)`;
                } else if (r.length - 1 === 101) {
                  r2_5[1] = r[101].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 102) {
                  r2_5[2] = r[102].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 103) {
                  r2_5[3] = r[103].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 104) {
                  r2_5[4] = r[104].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 105) {
                  r2_5[5] = r[105].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 106) {
                  r2_6[0] = `มูลค่าหุ้นทางบัญชีต่อหุ้น (บาท)`;
                } else if (r.length - 1 === 107) {
                  r2_6[1] = r[107].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 108) {
                  r2_6[2] = r[108].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 109) {
                  r2_6[3] = r[109].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 110) {
                  r2_6[4] = r[110].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 111) {
                  r2_6[5] = r[111].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 112) {
                  r2_7[0] = `อัตราส่วนเงินปันผลตอบแทน(%)`;
                } else if (r.length - 1 === 113) {
                  r2_7[1] = r[113].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 114) {
                  r2_7[2] = r[114].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 115) {
                  r2_7[3] = r[115].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 116) {
                  r2_7[4] = r[116].replace("&nbsp;&nbsp;", "").trim();
                } else if (r.length - 1 === 117) {
                  r2_7[5] = r[117].replace("&nbsp;&nbsp;", "").trim();
                }
              }
            }
          }

          t1.push(
            [r1],
            [r1_1, r1_2, r1_3, r1_4, r1_5, r1_6, r1_7, r1_8, r1_9, r1_10]
          );
          t2.push([r2], [r2_1, r2_2, r2_3, r2_4, r2_5, r2_6, r2_7]);

          commit("set_stock_financial_state", [t1, t2]);
        })
        .catch(function(err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_stock_note_data({ commit }) {
      axios
        .get(`${process.env.VUE_APP_BACKEND}/mongoapi/stock/note`)
        .then(function(data) {
          // console.log(data);
          //state.stock_report = data.data;
          commit("set_stock_note", data.data);
        })
        .catch(function(err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_stock_save_note_data({ dispatch }, data) {
      axios
        .post(`${process.env.VUE_APP_BACKEND}/mongoapi/stock/note`, data)
        .then(function() {
          // console.log(data);
          Swal.fire("บันทึกข้อมูลเรียบร้อย", "โปรดดูข้อมูลจดบันทึก", "success");
        })
        .then(() => {
          dispatch("load_stock_note_data");
        })
        .catch(function(err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_stock_del_note_data({ dispatch }, id) {
      axios
        .delete(`${process.env.VUE_APP_BACKEND}/mongoapi/stock/note/${id}`)
        .then(function() {
          // console.log(data);
          Swal.fire("ลบเรียบร้อย", "ลบข้อมูลจดบันทึกเรียบร้อย", "success");
          dispatch("load_stock_note_data");
        })
        .catch(function(err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_stock_report_data({ commit }) {
      axios
        .get(`${process.env.VUE_APP_BACKEND}/mongoapi/stock/report`)
        .then(function(data) {
          // console.log(data.data);
          //state.stock_report = data.data;
          commit("set_stock_report_state", data.data);
        })
        .catch(function(err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_stock_reportXD_data({ commit }, id) {
      axios
        .post(`${process.env.VUE_APP_BACKEND}/api/stock/calendarsecxd`, {
          id: id,
        })
        .then(function(data) {
          // console.log(data);

          let doc = new DOMParser().parseFromString(data.data, "text/html");

          let i = 0;
          let data_xd = [];
          let data_xd_new = [];
          for (const table of doc.getElementsByTagName("table")) {
            for (const td of table.rows) {
              for (const value of td.cells) {
                data_xd.push(value.innerText);
                if (i > 7) {
                  data_xd_new.push(
                    data_xd[i]
                      .replace(/\r?\n|\r/g, "")
                      .trim()
                      .split(" ")
                  );
                }
                i++;
              }
            }
          }

          let data_xd_edit = [];
          for (const iterator of data_xd_new) {
            for (const data of iterator) {
              if (data.length > 0) {
                if (data !== "-") {
                  if (data !== "XD") {
                    data_xd_edit.push(data);
                  }
                }
              }
            }
          }

          let check_number = [];

          for (let index = 0; index < data_xd_edit.length; index++) {
            const element = data_xd_edit[index];
            switch (element) {
              case "1":
                check_number.push(index);
                break;
              case "2":
                check_number.push(index);
                break;
              case "3":
                check_number.push(index);
                break;
              case "4":
                check_number.push(index);
                break;
              case "5":
                check_number.push(index);
                break;
              case "6":
                check_number.push(index);
                break;
              case "7":
                check_number.push(index);
                break;
              case "8":
                check_number.push(index);
                break;
              case "9":
                check_number.push(index);
                break;
              case "10":
                check_number.push(index);
                break;
              case "11":
                check_number.push(index);
                break;
              case "12":
                check_number.push(index);
                break;
              case "13":
                check_number.push(index);
                break;
              case "14":
                check_number.push(index);
                break;
              case "15":
                check_number.push(index);
                break;
              case "16":
                check_number.push(index);
                break;
              case "17":
                check_number.push(index);
                break;
              case "18":
                check_number.push(index);
                break;
              case "19":
                check_number.push(index);
                break;
              case "20":
                check_number.push(index);
                break;
              case "21":
                check_number.push(index);
                break;
              case "22":
                check_number.push(index);
                break;
              case "23":
                check_number.push(index);
                break;
              case "24":
                check_number.push(index);
                break;
              case "25":
                check_number.push(index);
                break;
              case "26":
                check_number.push(index);
                break;
              case "27":
                check_number.push(index);
                break;
              case "28":
                check_number.push(index);
                break;
              case "29":
                check_number.push(index);
                break;
              case "30":
                check_number.push(index);
                break;
              case "31":
                check_number.push(index);
                break;
              default:
                break;
            }
          }

          for (let i = 0; i < check_number.length; i++) {
            commit(
              "set_stock_reportXD_state",
              data_xd_edit.slice(check_number[i], check_number[i + 1])
            );
          }
        })
        .catch(function(err) {
          console.log(err);
        });
    },
    load_stock_save_report_data(state, data) {
      axios
        .post(`${process.env.VUE_APP_BACKEND}/mongoapi/stock/report`, data)
        .then(function(s) {
          // console.log(s);
          if (s.data.msg === 1) {
            Swal.fire({
              title: "บันทึกข้อมูลเรียบร้อย",
              icon: "success",
              position: "bottom-start",
              showConfirmButton: false,
              timer: 1500,
            });
          }

          // Swal.fire("บันทึกข้อมูลเรียบร้อย", "โปรดดูข้อมูลรายงาน", "success");
        })
        .catch(function(err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_stock_del_report_data({ dispatch }, id) {
      axios
        .delete(`${process.env.VUE_APP_BACKEND}/mongoapi/stock/report/${id}`)
        .then(function() {
          // console.log(data);
          Swal.fire("ลบเรียบร้อย", "ลบข้อมูลรายงานเรียบร้อย", "success");
          dispatch("load_stock_report_data");
        })
        .catch(function(err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_stock_data_success_data({ commit }) {
      commit("set_stock_data_success_state");
    },
  },
  getters: {
    get_stock_quote_financial_5_year_sum: (state) => {
      let data = 0;

      let check = state.stock_rightsandbenefit_summary.length;
      if (check > 5) {
        check = 5;
      }

      for (let index = 0; index < check; index++) {
        // const element = array[index];
        data =
          data +
          (state.stock_rightsandbenefit_summary[index].div /
            state.stock_quote_financial_5_year[index]) *
            100;
      }

     //  console.log(data / check);

      return data / check;
    },
    get_stock_quote_financial_5_year: (state) => {
      return state.stock_quote_financial_5_year;
    },
    get_stock_rightsandbenefit_r: (state) => {
      const div = [];

      for (const data_r of state.stock_rightsandbenefit_summary) {
        div.push(data_r.div);
      }

      let data_r_max = Math.max(...div);
      let data_r_min = Math.min(...div);

      return data_r_max - data_r_min;
    },
    get_stock_rightsandbenefit_sd: (state) => {
      const div = [];

      for (const data_r of state.stock_rightsandbenefit_summary) {
        div.push(data_r.div);
      }

      const n = div.length;
      if (n > 0) {
        const mean = div.reduce((a = 0, b = 0) => a + b) / n;
        return Math.sqrt(
          div
            .map((x) => Math.pow(x - mean, 2))
            .reduce((a = 0, b = 0) => a + b) / n
        );
      }
    },
    get_stock_date_xd: (state) => {
      let DD = state.stock_date_xd.substring(0, 2);
      let MM = state.stock_date_xd.substring(5, 3);
      let YY =
        parseInt(
          state.stock_date_xd.substring(
            state.stock_date_xd.length - 2,
            state.stock_date_xd.length
          )
        ) + 2543;

      return `${YY}-${MM}-${DD}`;
    },
    get_stock_company_profile: (state) => {
      return state.stock_company_profile;
    },
    get_stock_month_thai: (state) => {
      return state.stock_month_thai;
    },
    get_stock_xd_id_data: (state) => {
      return state.stock_xd_id;
    },
    get_stock_name_data: (state) => {
      return state.stock_name;
    },
    get_stock_note_data: (state) => {
      return state.stock_note;
    },
    get_stock_report_data: (state) => {
      return state.stock_report;
    },
    get_stock_reportXD_data: (state) => {
      return state.stock_report_xd;
    },
    get_stock_choose_buy_data: (state) => {
      state.stock_choose_buy =
        (state.stock_rightsandbenefit_summary_div_avg /
          0.07 /
          state.stock_last_quote) *
        100;
      return state.stock_choose_buy;
    },
    get_stock_last_quote_data: (state) => {
      return state.stock_last_quote;
    },
    get_stock_quote_data: (state) => {
      return state.stock_quote;
    },
    get_stock_quote_detail_data: (state) => {
      return state.stock_quote_detail;
    },
    get_stock_rightsandbenefit_data: (state) => {
      return state.stock_rightsandbenefit;
    },
    get_stock_rightsandbenefit_detail_data: (state) => {
      return state.stock_rightsandbenefit_detail;
    },
    get_stock_rightsandbenefit_summary_data: (state) => {
      return state.stock_rightsandbenefit_summary;
    },
    get_stock_rightsandbenefit_summary_div_avg_data: (state) => {
      return state.stock_rightsandbenefit_summary_div_avg;
    },
    get_stock_historical_data: (state) => {
      return state.stock_historical;
    },
    get_stock_financial_data: (state) => {
      return state.stock_financial;
    },
    get_stock_data_success_data: (state) => {
      return state.stock_data_success;
    },
  },
  modules: {},
};

export default stock;
