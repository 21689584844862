<template>
  <div>
    <Navbar />
    <div class="container-fluid">
      <div class="margin-top-100">
        <h4 class="mb-3">ข้อมูลผู้ใช้</h4>
        <div class="table-responsive">
          <table class="table table-light table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Email</th>
                <th scope="col">Username</th>
                <th scope="col">Tier</th>
                <th scope="col">Fullname</th>
                <th scope="col">Tels</th>
                <th scope="col">Created</th>
                <th scope="col">Manage</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(user, index) in this.$store.getters.get_user"
                :key="index"
              >
                <th scope="row">{{ index + 1 }}</th>
                <td>{{ user.email }}</td>
                <td>{{ user.username }}</td>
                <td>
                  <div
                    v-for="(status, index) in user.aim_statuses"
                    :key="index"
                  >
                    {{ status.tier }}
                  </div>
                </td>
                <td>
                  <div
                    v-for="(address, index) in user.aim_addresses"
                    :key="index"
                  >
                    <span v-if="address.firstname || address.lastname">
                      {{ address.firstname }} {{ address.lastname }}
                    </span>
                    <span v-else> ไม่พบข้อมูล </span>
                    <hr
                      v-if="
                        user.aim_addresses.length > 1 &&
                        user.aim_addresses.length != index + 1
                      "
                    />
                  </div>
                </td>
                <td>
                  <div v-for="(social, index) in user.aim_socials" :key="index">
                    {{ social.mobilephone || "ไม่พบข้อมูล" }}
                  </div>
                </td>

                <td>
                  {{ dateTime(user.createdAt) }}
                </td>
                <td>
                  <span class="px-1">
                    <button
                      @click="tier(user.id, user.email)"
                      class="btn btn-primary"
                    >
                      ระดับ
                    </button>
                  </span>
                  <span class="px-1">
                    <button
                      @click="edit(user.id, user.email)"
                      class="btn btn-warning"
                    >
                      รหัสผ่าน
                    </button> </span
                  ><span class="px-1"
                    ><button @click="del(user.id)" class="btn btn-danger">
                      ลบ
                    </button></span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "../components/Footer.vue";
import moment from "moment";

import Swal from "sweetalert2";

export default {
  name: "User",
  components: {
    Navbar,
    Footer,
  },
  mounted() {
    //if (!this.$store.getters.get_user) {
    this.$store.dispatch("LoadUsers");
    //}
  },
  methods: {
    del(id) {
      Swal.fire({
        title: "คุณต้องการลบข้อมูลผู้ใช้ ?",
        text: "ระบบจะลบข้อมูลทั้งหมดของผู้ใช้ทั้งหมด",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("DelUser", id);
        }
      });
    },
    async edit(id, email) {
      // console.log(id);
      const { value: password } = await Swal.fire({
        title: "กรุณาระบุรหัสผ่านใหม่",
        text: `${email}`,
        input: "password",
        inputLabel: "รหัสผ่าน",
        inputPlaceholder: "กรอกรหัสผ่าน",
        inputAttributes: {
          maxlength: 10,
          autocapitalize: "off",
          autocorrect: "off",
        },
      });

      if (password) {
        this.$store.dispatch("EditUser", { id, password });
      }
    },
    async tier(id, email) {
      // console.log(id);
      const { value: tier } = await Swal.fire({
        title: "กรุณาระบุระดับผู้ใช้",
        text: `${email}`,
        input: "range",
        inputLabel: "ระดับ Tier",
        inputAttributes: {
          min: 0,
          max: 15,
          step: 1,
        },
        inputValue: 0,
      });

      if (tier) {
        this.$store.dispatch("EditTierUser", { id, tier });
        // console.log(tier);
      }
    },
    dateTime(Time) {
      return moment(Time).format("DD-MM-YYYY MM:mm:ss ZZ");
    },
  },
};
</script>
