// import moment from "moment";
import axios from "axios";
import bcrypt from "bcryptjs";
import Swal from "sweetalert2";
import router from "../router/index";

const register = {
  state: {
    register: {
      email: "", // อีเมลผู้สมัคร
      password: "", // รหัสผ่านผู้สมัคร
      passwordconfirm: "", // ยืนยันรหัสผ่าน
      username: "", // ชื่อผู้ใช้
      // titlename: "", // คำนำหน้าชื่อ
      // firstname: "", // ชื่อจริง
      // lastname: "", // นามสกุล
      // number: "", // เลขที่บ้าน
      // detail: "", // ข้อมูลที่อยู่เพิ่มเติม หมู่ / ตรอก / ซอย / หมู่บ้าน
      // road: "", // ถนน
      // subdistrict: "", // ตำบล
      // district: "", // อำเภอ
      // province: "", // จังหวัด
      // zipcode: "", // รหัสไปรษณีย์
      // workphone: "", // เบอร์โทรศัพท์ที่ทำงาน
      // telephone: "", // เบอร์โทรศัพท์ที่บ้าน
      // mobilephone: "", // โทรศัพท์มือถือ
      // faxphone: "", // แฟกซ์
      // facebook: "", // เฟสบุ๊คผู้สมัคร
      // youtube: "", // ช่องยูทูป
      // instagram: "", // อินตราแกรม
      // twitter: "", // ทวิตเตอร์
      // line: "", // ไลน์
    },
    // status: {
    //   tier: 0, // ระดับสมาชิก
    //   startdate: "", // วันเริ่มต้นใช้งาน pro
    //   enddate: "", // วันสิ้นสุดการใช้งาน
    //   active: 0, // เปิด ปิด บัญชี
    // },
  },
  mutations: {
    set_reset_register_state(state) {
      state.register.email = "";
      state.register.password = "";
      state.register.username = "";
      state.register.passwordconfirm = "";
      // state.register.titlename = "";
      // state.register.firstname = "";
      // state.register.lastname = "";
      // state.register.number = "";
      // state.register.detail = "";
      // state.register.road = "";
      // state.register.subdistrict = "";
      // state.register.district = "";
      // state.register.province = "";
      // state.register.zipcode = "";
      // state.register.workphone = "";
      // state.register.telephone = "";
      // state.register.mobilephone = "";
      // state.register.faxphone = "";
      // state.register.facebook = "";
      // state.register.youtube = "";
      // state.register.instagram = "";
      // state.register.twitter = "";
      // state.register.line = "";
      // state.status.tier = 0;
      // state.status.startdate = "";
      // state.status.enddate = "";
      // state.status.active = 0;
    },
    set_save_register_state(state) {
      // state.status.startdate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      // state.status.enddate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      Swal.fire({
        allowEscapeKey: true,
        showConfirmButton: false,
        allowOutsideClick: false,
        title: "Loading.....",
        text: "กรุณารอสักครู่",
      });

      bcrypt.genSalt(11, function(err, salt) {
        // Store hash in your password DB.
        bcrypt.hash(state.register.password, salt, function(err, hash) {
          axios
            .post(`${process.env.VUE_APP_BACKEND}/mysqlapi/register`, {
              email: state.register.email,
              password: hash,
              username: state.register.username,
            })
            .then(function(response) {
              Swal.fire({
                title: response.data.title,
                text: response.data.text,
                icon: response.data.icon,
                showConfirmButton: false,
                timer: response.data.timer,
              });

              if (response.data.status === 1) {
                state.register.email = "";
                state.register.password = "";
                state.register.username = "";
                state.register.passwordconfirm = "";

                router.push({ name: "Login" });
              }

              // commit("reset_register_state");
            });
        });
      });
    },
  },
  actions: {
    register_resetstate({ commit }) {
      commit("set_reset_register_state");
    },
    register_state({ commit }) {
      commit("set_save_register_state");
    },
  },
  getters: {
    get_register_state: (state) => {
      return state.register;
    },
  },
};

export default register;
