<template>
  <div>
    <Navbar />
    <div class="container-fluid">
      <div class="margin-top-100 p-3">
        <h3 v-if="this.$store.getters.get_sentence_data_random !== null">
          ประโยคทั้งหมด:
          {{ this.$store.getters.get_sentence_data_random.count }} คำ |
          ท่องประโยคแล้ว:
          {{
            this.$store.getters.get_sentence_data_random.totalAmount[0]
              .totalAmount
          }}
          ครั้ง | ตอบถูกทั้งหมด:
          {{
            this.$store.getters.get_sentence_data_random.totalAmount[0]
              .totalCorrect
          }}
          ครั้ง | ตอบผิดทั้งหมด:
          {{
            this.$store.getters.get_sentence_data_random.totalAmount[0]
              .totalWrong
          }}
          ครั้ง
          <button @click="clear()" class="btn btn-danger">Clear</button>
        </h3>
        <hr />
        <div class="row">
          <div class="col-md-3">
            <div class="mb-3">
              <label class="form-label">เพิ่มประโยคภาษาอังกฤษ</label>
              <input
                v-model="sentence_name_en"
                ref="sentence_name_en"
                type="text"
                class="form-control"
                placeholder="ประโยคภาษาอังกฤษ"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">คำแปลภาษาไทย</label>
              <input
                v-model="sentence_name_th"
                ref="sentence_name_th"
                type="text"
                class="form-control"
                placeholder="คำแปลภาษาไทย"
              />
            </div>
            <div class="mb-3">
              <div class="d-grid gap-2 d-md-block">
                <button class="btn btn-primary" @click="save" type="button">
                  เพิ่ม
                </button>
                <span class="p-2"></span>
                <button class="btn btn-danger" @click="reset" type="button">
                  ยกเลิก
                </button>
              </div>
            </div>
            <div class="mb-3">
              <p>10 ประโยคที่เพิ่มล่าสุด</p>
              <div class="table-responsive">
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th scope="col" class="text-center">คำศัพท์</th>
                      <th scope="col" class="text-center">คำแปล</th>
                      <th scope="col" class="text-center">จัดการ</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(data, index) in this.$store.getters
                        .get_sentence_data"
                      :key="index"
                    >
                      <td class="text-center">
                        {{ data.en }}
                      </td>
                      <td class="text-center">
                        {{ data.th }}
                      </td>
                      <td class="text-center">
                        <button @click="del(data._id)" class="btn btn-danger">
                          ลบ
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div
              v-if="this.$store.getters.get_sentence_data_random !== null"
              class="card"
              style="width: 100%;"
            >
              <div class="card-header">
                ประโยค:
                {{ this.$store.getters.get_sentence_data_random.en }}
                แสดงทั้งหมด:
                {{ this.$store.getters.get_sentence_data_random.sum }} ครั้ง
                ตอบถูก:
                {{ this.$store.getters.get_sentence_data_random.correct }}
                ครั้ง ตอบผิด:
                {{ this.$store.getters.get_sentence_data_random.wrong }} ครั้ง
              </div>
              <div class="card-body">
                <h1 class="card-title mt-4 mb-5 text-center">
                  {{ this.$store.getters.get_sentence_data_random.en }}
                </h1>

                <div class="d-grid gap-3">
                  <button
                    @click="
                      answer(this.$store.getters.get_sentence_data_random.a1)
                    "
                    class="btn btn-outline-primary btn-lg"
                  >
                    {{ this.$store.getters.get_sentence_data_random.a1 }}
                  </button>
                  <button
                    @click="
                      answer(this.$store.getters.get_sentence_data_random.a2)
                    "
                    class="btn btn-outline-primary btn-lg"
                  >
                    {{ this.$store.getters.get_sentence_data_random.a2 }}
                  </button>
                  <button
                    @click="
                      answer(this.$store.getters.get_sentence_data_random.a3)
                    "
                    class="btn btn-outline-primary btn-lg"
                  >
                    {{ this.$store.getters.get_sentence_data_random.a3 }}
                  </button>
                  <button
                    @click="
                      answer(this.$store.getters.get_sentence_data_random.a4)
                    "
                    class="btn btn-outline-primary btn-lg"
                  >
                    {{ this.$store.getters.get_sentence_data_random.a4 }}
                  </button>
                  <button
                    @click="
                      answer(this.$store.getters.get_sentence_data_random.a5)
                    "
                    class="btn btn-outline-primary btn-lg"
                  >
                    {{ this.$store.getters.get_sentence_data_random.a5 }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Footer from "../components/Footer.vue";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "Sentence",
  components: {
    Navbar,
    Footer,
  },
  mounted() {
    this.$refs.sentence_name_en.focus();
    this.$store.dispatch("load_sentence_data");
    this.$store.dispatch("load_random_sentence_data");
  },
  data() {
    return {
      sentence_name_en: "",
      sentence_name_th: "",
    };
  },
  methods: {
    answer(ans) {
      this.$store.dispatch("load_random_sentence_data");
      // console.log(this.$store.getters.get_sentence_data_random);
      if (ans === this.$store.getters.get_sentence_data_random.th) {
        axios.post(
          `${process.env.VUE_APP_BACKEND}/mongoapi/sentence/update`,
          {
            check: 1,
            id: this.$store.getters.get_sentence_data_random.id,
            correct: this.$store.getters.get_sentence_data_random.correct,
            wrong: this.$store.getters.get_sentence_data_random.wrong,
            sum: this.$store.getters.get_sentence_data_random.sum,
          }
        );
        Swal.fire({
          title: "เป็นคำตอบที่ถูกต้อง",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      } else {
        axios.post(
          `${process.env.VUE_APP_BACKEND}/mongoapi/sentence/update`,
          {
            check: 0,
            id: this.$store.getters.get_sentence_data_random.id,
            correct: this.$store.getters.get_sentence_data_random.correct,
            wrong: this.$store.getters.get_sentence_data_random.wrong,
            sum: this.$store.getters.get_sentence_data_random.sum,
          }
        );
        Swal.fire({
          title: "เป็นคำตอบที่ผิด",
          icon: "error",
          text: `${this.$store.getters.get_sentence_data_random.en} : ${this.$store.getters.get_sentence_data_random.th}`,
          timer: 2000,
          showConfirmButton: false,
        });
      }
    },
    save() {
      //   console.log(this.sentence_name_en);
      //   console.log(this.sentence_name_th);

      if (
        this.sentence_name_en.trim() === "" &&
        this.sentence_name_th.trim() === ""
      ) {
        Swal.fire({
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          icon: "error",
          timer: 1200,
          showConfirmButton: false,
        });
        setTimeout(() => {
          if (this.sentence_name_en.trim() === "") {
            this.$refs.sentence_name_en.focus();
          }
        }, 1500);
      } else {
        Swal.fire({
          title: "คุณต้องการบันทึกความจำนี้",
          text: "บันทึกข้อมูล",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "บันทึกข้อมูล",
          cancelButtonText: "ยกเลิก",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store.dispatch("load_sentence_save_data", {
              sentence_name_en: this.sentence_name_en.trim(),
              sentence_name_th: this.sentence_name_th.trim(),
            });
            this.sentence_name_en = "";
            this.sentence_name_th = "";
          }
        });
      }
    },
    clear() {
      Swal.fire({
        title: "คุณต้องการเคลียร์ข้อมูล",
        text: "เคลียร์ข้อมูล",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "ใช่, เคลียร์ข้อมูล",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("load_clear_sentence_data");
          this.$store.dispatch("load_random_sentence_data");
        }
      });
    },
    reset() {
      this.sentence_name_en = "";
      this.sentence_name_th = "";
      this.$refs.sentence_name_en.focus();
    },
    del(id) {
      // console.log(id);
      Swal.fire({
        title: "คุณต้องการลบข้อมูล",
        text: "ลบข้อมูล",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "ใช่, ลบข้อมูล",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("load_sentence_del_data", id);
        }
      });
    },
  },
};
</script>
