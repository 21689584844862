<template>
  <div>
    <Navbar />
    <div class="container-fluid">
      <div class="margin-top-100">
        <h1>รายงานปันผล</h1>
        <div class="row">
          <div class="col-md-3">
            <div class="row p-2">
              <div class="text-center mb-2"><b>เรียงข้อมูล</b></div>
              <button class="btn btn-primary mb-2" @click="s('date_xd')">
                ตามวันที่ XD
              </button>
              <button class="btn btn-primary mb-2" @click="s('company')">
                ตามชื่อหุ้น
              </button>
              <button class="btn btn-primary mb-2" @click="s('should_buy')">
                ตามควรซื้อ %
              </button>
              <button
                class="btn btn-primary mb-2"
                @click="s('dividend_average')"
              >
                ตามเฉลี่ยปันผล
              </button>
              <button class="btn btn-primary mb-2" @click="s('dividend')">
                ตาม Dividend
              </button>
              <button class="btn btn-primary mb-2" @click="s('range')">
                ตามพิสัย
              </button>
              <button class="btn btn-primary mb-2" @click="s('sd')">
                ตาม SD
              </button>
              <button class="btn btn-primary mb-2" @click="s('current_price')">
                ตามราคาล่าสุด
              </button>
              <button class="btn btn-primary mb-2" @click="s('price_to_buy')">
                ตามราคาที่ควรซื้อ
              </button>
            </div>
            <div class="row p-2"></div>
          </div>
          <div class="col-md-8">
            <div class="table-responsive">
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th scope="col" class="text-center">วันที่ XD</th>
                    <th scope="col" class="text-center">บริษัท</th>
                    <th scope="col" class="text-center">ควรซื้อ %</th>
                    <th scope="col" class="text-center">เฉลี่ยปันผล</th>
                    <th scope="col" class="text-center">Dividend</th>
                    <th scope="col" class="text-center">พิสัย</th>
                    <th scope="col" class="text-center">SD</th>
                    <th scope="col" class="text-center">ราคาล่าสุด</th>
                    <th scope="col" class="text-center">ราคาที่ควรซื้อ</th>
                    <th scope="col" class="text-center">จัดการ</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- <span v-if="this.table_data != null"> -->
                  <tr
                    v-for="(row, index) in this.$store.getters
                      .get_stock_report_data"
                    :key="index"
                  >
                    <td class="text-center">
                      {{ row.date_xd }}
                    </td>
                    <td class="text-center">
                      <a href="#" @click="url(row.company)">
                        {{ row.company }}
                      </a>
                    </td>
                    <td class="table-success text-center">
                      {{
                        row.should_buy === null
                          ? "null"
                          : row.should_buy.toFixed(2)
                      }}
                    </td>
                    <td class="text-center">
                      {{
                        row.dividend_average === null
                          ? "null"
                          : row.dividend_average.toFixed(5)
                      }}
                    </td>
                    <td class="text-center">
                      {{
                        row.dividend === null ? "null" : row.dividend.toFixed(2)
                      }}
                      %
                    </td>
                    <td class="text-center">
                      {{ row.range === null ? "null" : row.range.toFixed(5) }}
                    </td>
                    <td class="text-center">
                      {{ row.sd === null ? "null" : row.sd.toFixed(5) }}
                    </td>
                    <td class="text-center">
                      {{
                        row.current_price === null
                          ? "null"
                          : row.current_price.toFixed(2)
                      }}
                      ({{
                        row.quote_percent === null || row.quote_percent === undefined || row.quote_percent === ""
                          ? "-"
                          : row.quote_percent >= 0 ? "+"+row.quote_percent.toFixed(2) : row.quote_percent.toFixed(2)
                      }} %)
                    </td>
                    <td class="table-success text-center">
                      {{
                        row.price_to_buy === null
                          ? "null"
                          : row.price_to_buy.toFixed(2)
                      }}
                    </td>
                    <td class="text-center">
                      <button @click="del(row._id)" class="btn btn-danger">
                        ลบ
                      </button>
                    </td>
                  </tr>
                  <!-- </span> -->
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-md-3"></div>
        </div>

        <!-- <hr />
        <div>
          {{ this.$store.getters.get_stock_report_data }}
        </div> -->
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../components/Navbar.vue";
import Footer from "../../components/Footer.vue";

import Swal from "sweetalert2";

export default {
  name: "Report",
  components: {
    Navbar,
    Footer,
  },
  mounted() {
    this.$store.dispatch("load_stock_report_data");
  },
  data() {
    return {
      date_xd_sort: false,
      company_sort: false,
      should_buy_sort: false,
      dividend_average_sort: false,
      current_price_sort: false,
      price_to_buy_sort: false,
      dividend_sort: false,
      range_sort: false,
      sd_sort: false,
    };
  },
  methods: {
    s(s) {
      if (s === "company") {
        if (!this.company_sort) {
          this.$store.dispatch("load_stock_report_sort", "-company");
          this.company_sort = !this.company_sort;
        } else {
          this.$store.dispatch("load_stock_report_sort", "company");
          this.company_sort = !this.company_sort;
        }
      } else if (s === "date_xd") {
        if (!this.date_xd_sort) {
          this.$store.dispatch("load_stock_report_sort", "-date_xd");
          this.date_xd_sort = !this.date_xd_sort;
        } else {
          this.$store.dispatch("load_stock_report_sort", "date_xd");
          this.date_xd_sort = !this.date_xd_sort;
        }
      } else if (s === "should_buy") {
        if (!this.should_buy_sort) {
          this.$store.dispatch("load_stock_report_sort", "-should_buy");
          this.should_buy_sort = !this.should_buy_sort;
        } else {
          this.$store.dispatch("load_stock_report_sort", "should_buy");
          this.should_buy_sort = !this.should_buy_sort;
        }
      } else if (s === "dividend_average") {
        if (!this.dividend_average_sort) {
          this.$store.dispatch("load_stock_report_sort", "-dividend_average");
          this.dividend_average_sort = !this.dividend_average_sort;
        } else {
          this.$store.dispatch("load_stock_report_sort", "dividend_average");
          this.dividend_average_sort = !this.dividend_average_sort;
        }
      } else if (s === "dividend") {
        if (!this.dividend_sort) {
          this.$store.dispatch("load_stock_report_sort", "-dividend");
          this.dividend_sort = !this.dividend_sort;
        } else {
          this.$store.dispatch("load_stock_report_sort", "dividend");
          this.dividend_sort = !this.dividend_sort;
        }
      } else if (s === "range") {
        if (!this.range_sort) {
          this.$store.dispatch("load_stock_report_sort", "-range");
          this.range_sort = !this.range_sort;
        } else {
          this.$store.dispatch("load_stock_report_sort", "range");
          this.range_sort = !this.range_sort;
        }
      } else if (s === "sd") {
        if (!this.sd_sort) {
          this.$store.dispatch("load_stock_report_sort", "-sd");
          this.sd_sort = !this.sd_sort;
        } else {
          this.$store.dispatch("load_stock_report_sort", "sd");
          this.sd_sort = !this.sd_sort;
        }
      } else if (s === "current_price") {
        if (!this.current_price_sort) {
          this.$store.dispatch("load_stock_report_sort", "-current_price");
          this.current_price_sort = !this.current_price_sort;
        } else {
          this.$store.dispatch("load_stock_report_sort", "current_price");
          this.current_price_sort = !this.current_price_sort;
        }
      } else if (s === "price_to_buy") {
        if (!this.price_to_buy_sort) {
          this.$store.dispatch("load_stock_report_sort", "-price_to_buy");
          this.price_to_buy_sort = !this.price_to_buy_sort;
        } else {
          this.$store.dispatch("load_stock_report_sort", "price_to_buy");
          this.price_to_buy_sort = !this.price_to_buy_sort;
        }
      }
    },
    del(id) {
      // console.log(id);

      Swal.fire({
        title: "คุณต้องการลบข้อมูล",
        text: "ลบข้อมูลรายงานปันผล",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "ใช่, ลบรายงาน",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("load_stock_del_report_data", id);
        }
      });
    },
    url(s) {
      // return `https://www.settrade.com/C04_01_stock_quote_p1.jsp?txtSymbol=${c}`;
      this.$router.push({ name: "Stock" });
      this.$store.dispatch("reset_stock_state");
      this.$store.dispatch("load_stock_company_profile", s);
      this.$store.dispatch("load_stock_name_data", s);
      this.$store.dispatch("load_stock_quote_data", s);
      this.$store.dispatch("load_stock_rightsandbenefit_data", s);
      this.$store.dispatch("load_stock_historical_data", s);
      this.$store.dispatch("load_stock_financial_data", s);
      this.$store.dispatch("load_stock_data_success_data");
    },
  },
};
</script>
