<template>
  <div>
    <nav class="navbar navbar-expand-md navbar-dark bg-dark fixed-top">
      <div class="container-fluid">
        <!-- <a class="navbar-brand" href="#">Navbar</a> -->
        <img
          class="navbar-brand"
          src="../assets/img/a-512x512.png"
          width="45"
          height="50"
        />

        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarsExampleDefault"
          aria-controls="navbarsExampleDefault"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarsExampleDefault">
          <ul class="navbar-nav me-auto mb-2 mb-md-0">
            <li class="nav-item">
              <router-link class="nav-link" to="/" active-class="active" exact
                >หน้าหลัก</router-link
              >
            </li>
            <!-- <li
              v-if="this.$store.getters.get_auth.tier >= this.$store.getters.get_authorization.View.Stock"
              class="nav-item"
            >
              <router-link class="nav-link" to="/stock" active-class="active"
                >หุ้น</router-link
              >
            </li> -->
            <li
              v-if="this.$store.getters.get_auth.tier >= this.$store.getters.get_authorization.View.Stock"
              class="nav-item"
            >
              <router-link class="nav-link" to="/stock/note" active-class="active"
                >จดบันทึก</router-link
              >
            </li>
            <!-- <li
              v-if="this.$store.getters.get_auth.tier >= this.$store.getters.get_authorization.View.Stock"
              class="nav-item"
            >
              <router-link class="nav-link" to="/stock/report/xd" active-class="active"
                >รายงาน XD</router-link
              >
            </li> -->
            <li
              v-if="this.$store.getters.get_auth.tier >= this.$store.getters.get_authorization.View.Customer"
              class="nav-item"
            >
              <router-link class="nav-link" to="/customer" active-class="active"
                >ลูกค้า</router-link
              >
            </li>
            <li
              v-if="this.$store.getters.get_auth.tier >= this.$store.getters.get_authorization.View.Remember"
              class="nav-item"
            >
              <router-link class="nav-link" to="/remember" active-class="active"
                >โปรแกรมจดจำ</router-link
              >
            </li>
            <li
              v-if="this.$store.getters.get_auth.tier >= this.$store.getters.get_authorization.View.Remember"
              class="nav-item"
            >
              <router-link class="nav-link" to="/vocabulary" active-class="active"
                >ท่องศัพท์</router-link
              >
            </li>
            <li
              v-if="this.$store.getters.get_auth.tier >= this.$store.getters.get_authorization.View.Remember"
              class="nav-item"
            >
              <router-link class="nav-link" to="/sentence" active-class="active"
                >ท่องประโยค</router-link
              >
            </li>
            <li
              v-if="this.$store.getters.get_auth.tier >= this.$store.getters.get_authorization.View.User"
              class="nav-item"
            >
              <router-link class="nav-link" to="/user" active-class="active"
                >ผู้ใช้</router-link
              >
            </li>
            <li
              v-if="this.$store.getters.get_auth.tier >= this.$store.getters.get_authorization.View.Setting"
              class="nav-item"
            >
              <router-link class="nav-link" to="/setting" active-class="active"
                >ตั้งค่า</router-link
              >
            </li>

            <!-- <li class="nav-item active">
              <a class="nav-link" aria-current="page" href="#">AIM</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Link</a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link disabled"
                href="#"
                tabindex="-1"
                aria-disabled="true"
                >Disabled</a
              >
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="dropdown01"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                >Dropdown</a
              >
              <ul class="dropdown-menu" aria-labelledby="dropdown01">
                <li><a class="dropdown-item" href="#">Action</a></li>
                <li><a class="dropdown-item" href="#">Another action</a></li>
                <li>
                  <a class="dropdown-item" href="#">Something else here</a>
                </li>
              </ul>
            </li> -->
          </ul>

          <Logout />
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import Logout from "./Logout.vue";
export default {
  name: "Navbar",
  components: {
    Logout,
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped></style>
