<template>
  <div>
    <Navbar />
    <div class="container-fluid">
      <div class="margin-top-100 p-3">
        <h3 v-if="this.$store.getters.get_vocabulary_data_random !== null">
          คำศัพท์ทั้งหมด:
          {{ this.$store.getters.get_vocabulary_data_random.count }} คำ |
          ท่องศัพท์แล้ว:
          {{
            this.$store.getters.get_vocabulary_data_random.totalAmount[0]
              .totalAmount
          }}
          ครั้ง | ตอบถูกทั้งหมด:
          {{
            this.$store.getters.get_vocabulary_data_random.totalAmount[0]
              .totalCorrect
          }}
          ครั้ง | ตอบผิดทั้งหมด:
          {{
            this.$store.getters.get_vocabulary_data_random.totalAmount[0]
              .totalWrong
          }}
          ครั้ง
          <button @click="clear()" class="btn btn-danger">Clear</button>
        </h3>
        <hr />
        <div class="row">
          <div class="col-md-3">
            <div class="mb-3">
              <label class="form-label">เพิ่มคำศัพท์ภาษาอังกฤษ</label>
              <input
                v-model="vocabulary_name_en"
                ref="vocabulary_name_en"
                type="text"
                class="form-control"
                placeholder="คำศัพท์ภาษาอังกฤษ"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">คำแปลภาษาไทย</label>
              <input
                v-model="vocabulary_name_th"
                ref="vocabulary_name_th"
                type="text"
                class="form-control"
                placeholder="คำแปลภาษาไทย"
              />
            </div>
            <div class="mb-3">
              <div class="d-grid gap-2 d-md-block">
                <button class="btn btn-primary" @click="save" type="button">
                  เพิ่ม
                </button>
                <span class="p-2"></span>
                <button class="btn btn-danger" @click="reset" type="button">
                  ยกเลิก
                </button>
              </div>
            </div>
            <div class="card border-light">
              <div class="card-body">
                <div class="panel panel-sm">
                  <div class="panel-heading">
                    <h5>
                      เพิ่มศัพท์ : [
                      <i class="fas fa-link"></i>
                      <a
                        href="https://docs.google.com/spreadsheets/d/1sNt6IxBG-Xp0Hh2Bz22Xfhj3OVLCw1WQz0xdgExRg2g/edit?usp=sharing"
                        target="_blank"
                        >ตัวอย่างไฟล์</a
                      >
                      ]
                    </h5>
                  </div>
                  <div class="panel-body">
                    <div class="form-group">
                      <div class="col-lg-3">
                        <input
                          ref="input_file_csv"
                          type="file"
                          id="file"
                          @change="loadCSV($event)"
                        />
                        <label for="file" class="btn btn-light mt-2">
                          <i class="fas fa-upload mr-2"></i>
                          {{ text_input_file }}
                        </label>
                      </div>
                    </div>

                    <div class="col-sm-offset-3 col-sm-9">
                      <button
                        @click="method_data_file_vo()"
                        class="btn btn-primary"
                        :disabled="btn_data_file_voc"
                      >
                        เพิ่มคำศัพท์
                      </button>
                      <button @click="clearCSV()" class="btn btn-danger ml-3">
                        เคลียร์ข้อมูล
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3">
              <p>10 คำศัพท์ที่เพิ่มล่าสุด</p>
              <div class="table-responsive">
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th scope="col" class="text-center">คำศัพท์</th>
                      <th scope="col" class="text-center">คำแปล</th>
                      <th scope="col" class="text-center">จัดการ</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(data, index) in this.$store.getters
                        .get_vocabulary_data"
                      :key="index"
                    >
                      <td class="text-center">
                        {{ data.en }}
                      </td>
                      <td class="text-center">
                        {{ data.th }}
                      </td>
                      <td class="text-center">
                        <button @click="del(data._id)" class="btn btn-danger">
                          ลบ
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div
              v-if="this.$store.getters.get_vocabulary_data_random !== null"
              class="card"
              style="width: 100%;"
            >
              <div class="card-header">
                คำศัพท์:
                {{ this.$store.getters.get_vocabulary_data_random.en }}
                แสดงทั้งหมด:
                {{ this.$store.getters.get_vocabulary_data_random.sum }} ครั้ง
                ตอบถูก:
                {{ this.$store.getters.get_vocabulary_data_random.correct }}
                ครั้ง ตอบผิด:
                {{ this.$store.getters.get_vocabulary_data_random.wrong }} ครั้ง
              </div>
              <div class="card-body">
                <h1 class="card-title mt-4 mb-5 text-center">
                  {{ this.$store.getters.get_vocabulary_data_random.en }}
                </h1>

                <div class="d-grid gap-3">
                  <button
                    @click="
                      answer(this.$store.getters.get_vocabulary_data_random.a1)
                    "
                    class="btn btn-outline-primary btn-lg"
                  >
                    {{ this.$store.getters.get_vocabulary_data_random.a1 }}
                  </button>
                  <button
                    @click="
                      answer(this.$store.getters.get_vocabulary_data_random.a2)
                    "
                    class="btn btn-outline-primary btn-lg"
                  >
                    {{ this.$store.getters.get_vocabulary_data_random.a2 }}
                  </button>
                  <button
                    @click="
                      answer(this.$store.getters.get_vocabulary_data_random.a3)
                    "
                    class="btn btn-outline-primary btn-lg"
                  >
                    {{ this.$store.getters.get_vocabulary_data_random.a3 }}
                  </button>
                  <button
                    @click="
                      answer(this.$store.getters.get_vocabulary_data_random.a4)
                    "
                    class="btn btn-outline-primary btn-lg"
                  >
                    {{ this.$store.getters.get_vocabulary_data_random.a4 }}
                  </button>
                  <button
                    @click="
                      answer(this.$store.getters.get_vocabulary_data_random.a5)
                    "
                    class="btn btn-outline-primary btn-lg"
                  >
                    {{ this.$store.getters.get_vocabulary_data_random.a5 }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Footer from "../components/Footer.vue";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "Vocabulary",
  components: {
    Navbar,
    Footer,
  },
  mounted() {
    this.$refs.vocabulary_name_en.focus();
    this.$store.dispatch("load_vocabulary_data");
    this.$store.dispatch("load_random_vocabulary_data");
  },
  data() {
    return {
      vocabulary_name_en: "",
      vocabulary_name_th: "",
      btn_data_file_voc: true,
      parse_header: [],
      parse_csv: [],
      text_input_file: "กรุณาเลือกไฟล์",
    };
  },
  methods: {
    method_data_file_vo() {
      // console.log(this.parse_header);
      Swal.fire({
        allowEscapeKey: true,
        showConfirmButton: false,
        allowOutsideClick: false,
        title: "กำลังดึงข้อมูล",
        text: "กรุณารอสักครู่.....",
      });

      for (const key in this.parse_csv) {
        this.$store.dispatch("load_vocabulary_save_data", {
          vocabulary_name_en: this.parse_csv[key].col1,
          vocabulary_name_th: this.parse_csv[key].col2,
        });
      }

      Swal.fire("บันทึกข้อมูลเรียบร้อย", "สำเร็จ", "success");

      this.vocabulary_name_en = "";
      this.vocabulary_name_th = "";
      this.$store.dispatch("load_vocabulary_data");
      this.clearCSV();
    },
    method_swal_check(icon, data, timer) {
      // console.log("method_swal_check");
      Swal.fire({
        icon: icon,
        title: data,
        showConfirmButton: false,
        timer: timer,
      });
    },
    clearCSV() {
      this.parse_header = [];
      this.parse_csv = [];
      this.btn_data_file_voc = true;
      this.$refs.input_file_csv.value = "";
      this.text_input_file = "กรุณาเลือกไฟล์";
    },
    csvJSON(csv) {
      const header_check = ["col1", "col2", "check"];

      let lines = csv.split("\n");

      let result = [];
      let headers = lines[0].split(",");
      this.parse_header = lines[0].split(",");
      if (this.parse_header.length !== header_check.length) {
        this.clearCSV();
        this.method_swal_check("error", "จำนวน Colume ไม่ถูกต้อง", 2000);
        return;
      }
      for (const key in header_check) {
        if (header_check[key] !== this.parse_header[key].trim()) {
          this.clearCSV();
          Swal.fire({
            icon: "error",
            title: "ชื่อ Colume ไม่ถูกต้อง",
            text: header_check[key] + " " + this.parse_header[key],
            showConfirmButton: false,
            timer: 3000,
          });
        }
      }

      lines.map(function(line, indexLine) {
        if (indexLine < 1) return;

        let obj = {};
        let currentline = line.split(",");

        headers.map(function(header, indexHeader) {
          obj[header] = currentline[indexHeader];
        });

        result.push(obj);
      });
      this.btn_data_file_voc = false;
      return result;
    },
    loadCSV(e) {
      if (
        e.target.files[0].type === "text/csv" ||
        e.target.files[0].type === "application/vnd.ms-excel" ||
        e.target.files[0].type === "text/plain"
      ) {
        this.text_input_file = e.target.files[0].name;
        if (window.FileReader) {
          let reader = new FileReader();
          reader.readAsText(e.target.files[0]);
          // Handle errors load
          reader.onload = (event) => {
            let csv = event.target.result;
            this.parse_csv = this.csvJSON(csv);
          };
          reader.onerror = (evt) => {
            if (evt.target.error.name == "NotReadableError") {
              this.clearCSV();
              this.method_swal_check(
                "error",
                "ไม่สามารถอ่านไฟล์ได้ โปรดลองใหม่",
                2000
              );
            }
          };
        } else {
          this.clearCSV();
          this.method_swal_check(
            "error",
            "Browser ไม่รองรับกรุณาใช้ Chrome",
            2000
          );
        }
      } else {
        this.clearCSV();
        this.method_swal_check("error", "ไฟล์ไม่ถูกต้อง โปรดลองใหม่", 2000);
      }
    },
    answer(ans) {
      this.$store.dispatch("load_random_vocabulary_data");
      // console.log(this.$store.getters.get_vocabulary_data_random);
      if (ans === this.$store.getters.get_vocabulary_data_random.th) {
        axios.post(
          `${process.env.VUE_APP_BACKEND}/mongoapi/vocabulary/update`,
          {
            check: 1,
            id: this.$store.getters.get_vocabulary_data_random.id,
            correct: this.$store.getters.get_vocabulary_data_random.correct,
            wrong: this.$store.getters.get_vocabulary_data_random.wrong,
            sum: this.$store.getters.get_vocabulary_data_random.sum,
          }
        );
        Swal.fire({
          title: "เป็นคำตอบที่ถูกต้อง",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      } else {
        axios.post(
          `${process.env.VUE_APP_BACKEND}/mongoapi/vocabulary/update`,
          {
            check: 0,
            id: this.$store.getters.get_vocabulary_data_random.id,
            correct: this.$store.getters.get_vocabulary_data_random.correct,
            wrong: this.$store.getters.get_vocabulary_data_random.wrong,
            sum: this.$store.getters.get_vocabulary_data_random.sum,
          }
        );
        Swal.fire({
          title: "เป็นคำตอบที่ผิด",
          icon: "error",
          text: `${this.$store.getters.get_vocabulary_data_random.en} : ${this.$store.getters.get_vocabulary_data_random.th}`,
          timer: 2000,
          showConfirmButton: false,
        });
      }
    },
    save() {
      //   console.log(this.vocabulary_name_en);
      //   console.log(this.vocabulary_name_th);

      if (
        this.vocabulary_name_en.trim() === "" &&
        this.vocabulary_name_th.trim() === ""
      ) {
        Swal.fire({
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          icon: "error",
          timer: 1200,
          showConfirmButton: false,
        });
        setTimeout(() => {
          if (this.vocabulary_name_en.trim() === "") {
            this.$refs.vocabulary_name_en.focus();
          }
        }, 1500);
      } else {
        Swal.fire({
          title: "คุณต้องการบันทึกความจำนี้",
          text: "บันทึกข้อมูล",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "บันทึกข้อมูล",
          cancelButtonText: "ยกเลิก",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store.dispatch("load_vocabulary_save_data", {
              vocabulary_name_en: this.vocabulary_name_en.trim(),
              vocabulary_name_th: this.vocabulary_name_th.trim(),
            });
            
            Swal.fire("บันทึกข้อมูลเรียบร้อย", "สำเร็จ", "success");
            this.vocabulary_name_en = "";
            this.vocabulary_name_th = "";
            this.$store.dispatch("load_vocabulary_data");
          }
        });
      }
    },
    clear() {
      Swal.fire({
        title: "คุณต้องการเคลียร์ข้อมูล",
        text: "เคลียร์ข้อมูล",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "ใช่, เคลียร์ข้อมูล",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("load_clear_vocabulary_data");
          this.$store.dispatch("load_random_vocabulary_data");
        }
      });
    },
    reset() {
      this.vocabulary_name_en = "";
      this.vocabulary_name_th = "";
      this.$refs.vocabulary_name_en.focus();
    },
    del(id) {
      // console.log(id);
      Swal.fire({
        title: "คุณต้องการลบข้อมูล",
        text: "ลบข้อมูล",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "ใช่, ลบข้อมูล",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("load_vocabulary_del_data", id);
        }
      });
    },
  },
};
</script>

<style scope>
[type="file"] {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

[type="file"] + label {
  background-color: #3ba575;
  border-radius: 4rem;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: inline-block;
  padding-left: 2rem 4rem;
}

[type="file"]:focus + label,
[type="file"] + label:hover {
  background-color: #42b883;
}

[type="file"]:focus + label {
  outline: 1px dotted #3ba575;
}
</style>
