import axios from "axios";
import Swal from "sweetalert2";

const sentence = {
  state: {
    sentence_data: null,
    sentence_add: null,
    sentence_hint: null,
    sentence_data_random: null,
  },
  mutations: {
    set_sentence_data_random(state, payload) {
      // console.log(payload)
      state.sentence_data_random = payload;
    },
    set_sentence_data(state, payload) {
      // console.log(payload)
      state.sentence_data = payload;
    },
    set_sentence_add(state, payload) {
      // console.log(payload)
      state.sentence_add = payload;
    },
    set_sentence_hint(state, payload) {
      // console.log(payload)
      state.sentence_hint = payload;
    },
  },
  actions: {
    load_clear_sentence_data({ commit }) {
      axios
        .get(`${process.env.VUE_APP_BACKEND}/mongoapi/sentence/clear`)
        .then(function (data) {
          // console.log(data);
          commit("set_sentence_data_random", data.data);
        })
        .catch(function (err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_random_sentence_data({ commit }) {
      axios
        .get(`${process.env.VUE_APP_BACKEND}/mongoapi/sentence/random`)
        .then(function (data) {
          // console.log(data);
          commit("set_sentence_data_random", data.data);
        })
        .catch(function (err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_sentence_data({
      commit
    }) {
      // console.log("Load sentence");
      axios
        .get(`${process.env.VUE_APP_BACKEND}/mongoapi/sentence/sentence`)
        .then(function (data) {
          // console.log(data);
          commit("set_sentence_data", data.data);
        })
        .catch(function (err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_sentence_save_data({
      dispatch
    }, data) {
      axios
        .post(`${process.env.VUE_APP_BACKEND}/mongoapi/sentence/sentence`, data)
        .then(function (res) {
          // console.log(data);
          if (res.data.check === 1) {
            // Swal.fire("บันทึกข้อมูลเรียบร้อย", res.data.msg, "success");

            Swal.fire({
              title: "บันทึกข้อมูลเรียบร้อย",
              icon: "success",
              text: res.data.msg,
              timer: 1000,
              showConfirmButton: false,
            });
          } else {
            // Swal.fire("ไม่สามารถบันทึกข้อมูลได้", res.data.msg, "error");

            Swal.fire({
              title: "ไม่สามารถบันทึกข้อมูลได้",
              text: res.data.msg,
              icon: "error",
              timer: 1000,
              showConfirmButton: false,
            });
          }
        })
        .then(() => {
          dispatch("load_sentence_data");
        })
        .catch(function (err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_sentence_del_data({
      dispatch
    }, id) {
      axios
        .delete(`${process.env.VUE_APP_BACKEND}/mongoapi/sentence/sentence/${id}`)
        .then(function () {
          // console.log(data);
          Swal.fire("ลบเรียบร้อย", "ลบข้อมูลเรียบร้อย", "success");
          dispatch("load_sentence_data");
        })
        .catch(function (err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_sentence_add_data({
      dispatch
    }, data) {
      axios
        .post(`${process.env.VUE_APP_BACKEND}/mongoapi/sentence/sentence/add`, data)
        .then(() => {
          dispatch("load_sentence_data");
        })
        .catch(function (err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_sentence_del_detail_data({
      dispatch
    }, id) {
      // console.log(id);
      axios
        .delete(`${process.env.VUE_APP_BACKEND}/mongoapi/sentence/sentence/detail/${id[0]}/${id[1]}`)
        .then(function () {
          // console.log(data);
          // Swal.fire("ลบเรียบร้อย", "ลบข้อมูลเรียบร้อย", "success");
          dispatch("load_sentence_data");
        })
        .catch(function (err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_sentence_c_detail_data({
      commit
    }, id) {
      // console.log(id);
      axios
        .post(`${process.env.VUE_APP_BACKEND}/mongoapi/sentence/sentence/detail/change/${id[0]}/${id[1]}`)
        .then(function (data) {
          // console.log(data.data.d);
          commit("set_sentence_add", data.data.d);
          commit("set_sentence_hint", data.data.h);
          // dispatch("load_sentence_data");
        })
        .catch(function (err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_sentence_c_update_detail_data({
      dispatch
    }, data) {
      // console.log(id);
      axios
        .post(`${process.env.VUE_APP_BACKEND}/mongoapi/sentence/sentence/detail/update`, [data[0], data[1], data[2], data[3]])
        .then(function () {
          // console.log(data);

          dispatch("load_sentence_data");
        })
        .catch(function (err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_sentence_c_update_header_detail_data({
      dispatch
    }, data) {
      // console.log(id);
      axios
        .post(`${process.env.VUE_APP_BACKEND}/mongoapi/sentence/sentence/detail/update/header`, [data[0], data[1]])
        .then(function () {
          // console.log(data);

          dispatch("load_sentence_data");
        })
        .catch(function (err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_sentence_detail_show_data({
      dispatch
    }, id) {
      // console.log(id);
      axios
        .post(`${process.env.VUE_APP_BACKEND}/mongoapi/sentence/sentence/detail/show`, [id[0], id[1], id[2]])
        .then(function () {
          // console.log(data);
          dispatch("load_sentence_data");
        })
        .catch(function (err) {
          // Crawling failed...
          console.log(err);
        });
    }
  },
  getters: {
    get_sentence_data_random: (state) => {
      return state.sentence_data_random;
    },
    get_sentence_data: (state) => {
      return state.sentence_data;
    },
    get_sentence_add: (state) => {
      return state.sentence_add;
    },
    get_sentence_hint: (state) => {
      return state.sentence_hint;
    },
  },
  modules: {},
};

export default sentence;