/**
 * TIER
 * 10 = Super Admin
 * 1 = User
 */

const authorization = {
  state: {
    View: {
      Home: 1,
      // Customer: 1,
      Note: 10,
      Setting: 1,
      Stock: 10,
      Report: 10,
      ReportXD: 10,
      User: 10,
      Remember: 10,
    },
  },
  mutations: {},
  actions: {},
  getters: {
    get_authorization(state) {
      return state;
    },
  },
  modules: {},
};

export default authorization;
