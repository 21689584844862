<template>
  <div>
    <Navbar />
    <div class="container-fluid">
      <div class="margin-top-100">
        <div class="p-3">
          <div class="card">
            <div class="card-header">AIM Account</div>
            <div class="card-body">
              <h5 class="card-title">สวัสดีทุกท่าน</h5>
              <p class="card-text">
                00110000 00111000 00110000 00110101 00110001 00110101 00110001 00110110 00110101 00110001
              </p>
              <a href="mailto:admin@aimaccount.com" class="btn btn-primary">กรุณาติดต่อ admin@aimaccount.com</a>
            </div>
          </div>
        </div>

        <!-- <div class="row">

          <div class="col-md-3">
            <span
              v-for="(company, index) in this.$store.getters
                .get_stock_company_profile[0]"
              :key="index"
              ><span v-if="company !== `ชื่อบริษัท`"
                >{{ company }}&nbsp;</span
              ></span
            ><br />
            <span
              v-for="(company, index) in this.$store.getters
                .get_stock_company_profile[4]"
              :key="index"
              ><span v-if="index < 2">{{ company }}&nbsp;</span></span
            >
          </div>

          <div class="col-md-3">
            <span
              v-for="(company, index) in this.$store.getters
                .get_stock_company_profile[1]"
              :key="index"
              ><span>{{ company }}&nbsp;</span></span
            >
          </div>

          <div class="col-md-3">
            <span
              v-for="(company, index) in this.$store.getters
                .get_stock_company_profile[2]"
              :key="index"
              ><span>{{ company }}&nbsp;</span></span
            ><br />
            <span
              v-for="(company, index) in this.$store.getters
                .get_stock_company_profile[3]"
              :key="index"
              ><span>{{ company }}&nbsp;</span></span
            >
          </div>

          <div class="col-md-3">
            <span
              v-for="(company, index) in this.$store.getters
                .get_stock_company_profile[5]"
              :key="index"
              ><span>{{ company }}&nbsp;</span></span
            >
            <br />
            <span
              v-for="(company, index) in this.$store.getters
                .get_stock_company_profile[6]"
              :key="index"
              ><span>{{ company }}&nbsp;</span></span
            >
            <br />
            <span
              v-for="(company, index) in this.$store.getters
                .get_stock_company_profile[7]"
              :key="index"
              ><span>{{ company }}&nbsp;</span></span
            >
            <br />
            <span
              v-for="(company, index) in this.$store.getters
                .get_stock_company_profile[8]"
              :key="index"
              ><span>{{ company }}&nbsp;</span></span
            >
          </div>

        </div> -->
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Navbar from "@/components/Navbar.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "Home",
  components: {
    // HelloWorld,
    Navbar,
    Footer,
  },
  data() {
    return {
      data: [],
    };
  },
  mounted() {
    // this.$store.dispatch("authCheck");
    // this.auth = this.$store.getters.get_auth;
    // this.$store.dispatch("load_stock_company_profile", "OR");
    // this.$router.push({ name: "Stock" });
  },
  methods: {
    // c() {
    //   console.log(data);
    // },
  },
};
</script>
