import axios from "axios";
import Swal from "sweetalert2";

const vocabulary = {
  state: {
    vocabulary_data: null,
    vocabulary_add: null,
    vocabulary_hint: null,
    vocabulary_data_random: null,
  },
  mutations: {
    set_vocabulary_data_random(state, payload) {
      // console.log(payload)
      state.vocabulary_data_random = payload;
    },
    set_vocabulary_data(state, payload) {
      // console.log(payload)
      state.vocabulary_data = payload;
    },
    set_vocabulary_add(state, payload) {
      // console.log(payload)
      state.vocabulary_add = payload;
    },
    set_vocabulary_hint(state, payload) {
      // console.log(payload)
      state.vocabulary_hint = payload;
    },
  },
  actions: {
    load_clear_vocabulary_data({ commit }) {
      axios
        .get(`${process.env.VUE_APP_BACKEND}/mongoapi/vocabulary/clear`)
        .then(function (data) {
          // console.log(data);
          commit("set_vocabulary_data_random", data.data);
        })
        .catch(function (err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_random_vocabulary_data({ commit }) {
      axios
        .get(`${process.env.VUE_APP_BACKEND}/mongoapi/vocabulary/random`)
        .then(function (data) {
          // console.log(data);
          commit("set_vocabulary_data_random", data.data);
        })
        .catch(function (err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_vocabulary_data({
      commit
    }) {
      // console.log("Load vocabulary");
      axios
        .get(`${process.env.VUE_APP_BACKEND}/mongoapi/vocabulary/vocabulary`)
        .then(function (data) {
          // console.log(data);
          commit("set_vocabulary_data", data.data);
        })
        .catch(function (err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_vocabulary_save_data({
      dispatch
    }, data) {
      axios
        .post(`${process.env.VUE_APP_BACKEND}/mongoapi/vocabulary/vocabulary`, data)
        // .then(function (res) {
        //   // console.log(data);
        //   if (res.data.check === 1) {
        //     // Swal.fire("บันทึกข้อมูลเรียบร้อย", res.data.msg, "success");

        //     Swal.fire({
        //       title: "บันทึกข้อมูลเรียบร้อย",
        //       icon: "success",
        //       text: res.data.msg,
        //       timer: 1000,
        //       showConfirmButton: false,
        //     });
        //   } else {
        //     // Swal.fire("ไม่สามารถบันทึกข้อมูลได้", res.data.msg, "error");

        //     Swal.fire({
        //       title: "ไม่สามารถบันทึกข้อมูลได้",
        //       text: res.data.msg,
        //       icon: "error",
        //       timer: 1000,
        //       showConfirmButton: false,
        //     });
        //   }
        // })
        .then(() => {
          dispatch("load_vocabulary_data");
        })
        .catch(function (err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_vocabulary_del_data({
      dispatch
    }, id) {
      axios
        .delete(`${process.env.VUE_APP_BACKEND}/mongoapi/vocabulary/vocabulary/${id}`)
        .then(function () {
          // console.log(data);
          Swal.fire("ลบเรียบร้อย", "ลบข้อมูลเรียบร้อย", "success");
          dispatch("load_vocabulary_data");
        })
        .catch(function (err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_vocabulary_add_data({
      dispatch
    }, data) {
      axios
        .post(`${process.env.VUE_APP_BACKEND}/mongoapi/vocabulary/vocabulary/add`, data)
        .then(() => {
          dispatch("load_vocabulary_data");
        })
        .catch(function (err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_vocabulary_del_detail_data({
      dispatch
    }, id) {
      // console.log(id);
      axios
        .delete(`${process.env.VUE_APP_BACKEND}/mongoapi/vocabulary/vocabulary/detail/${id[0]}/${id[1]}`)
        .then(function () {
          // console.log(data);
          // Swal.fire("ลบเรียบร้อย", "ลบข้อมูลเรียบร้อย", "success");
          dispatch("load_vocabulary_data");
        })
        .catch(function (err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_vocabulary_c_detail_data({
      commit
    }, id) {
      // console.log(id);
      axios
        .post(`${process.env.VUE_APP_BACKEND}/mongoapi/vocabulary/vocabulary/detail/change/${id[0]}/${id[1]}`)
        .then(function (data) {
          // console.log(data.data.d);
          commit("set_vocabulary_add", data.data.d);
          commit("set_vocabulary_hint", data.data.h);
          // dispatch("load_vocabulary_data");
        })
        .catch(function (err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_vocabulary_c_update_detail_data({
      dispatch
    }, data) {
      // console.log(id);
      axios
        .post(`${process.env.VUE_APP_BACKEND}/mongoapi/vocabulary/vocabulary/detail/update`, [data[0], data[1], data[2], data[3]])
        .then(function () {
          // console.log(data);

          dispatch("load_vocabulary_data");
        })
        .catch(function (err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_vocabulary_c_update_header_detail_data({
      dispatch
    }, data) {
      // console.log(id);
      axios
        .post(`${process.env.VUE_APP_BACKEND}/mongoapi/vocabulary/vocabulary/detail/update/header`, [data[0], data[1]])
        .then(function () {
          // console.log(data);

          dispatch("load_vocabulary_data");
        })
        .catch(function (err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_vocabulary_detail_show_data({
      dispatch
    }, id) {
      // console.log(id);
      axios
        .post(`${process.env.VUE_APP_BACKEND}/mongoapi/vocabulary/vocabulary/detail/show`, [id[0], id[1], id[2]])
        .then(function () {
          // console.log(data);
          dispatch("load_vocabulary_data");
        })
        .catch(function (err) {
          // Crawling failed...
          console.log(err);
        });
    }
  },
  getters: {
    get_vocabulary_data_random: (state) => {
      return state.vocabulary_data_random;
    },
    get_vocabulary_data: (state) => {
      return state.vocabulary_data;
    },
    get_vocabulary_add: (state) => {
      return state.vocabulary_add;
    },
    get_vocabulary_hint: (state) => {
      return state.vocabulary_hint;
    },
  },
  modules: {},
};

export default vocabulary;