<template>
  <router-view></router-view>
</template>

<script>
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import "../node_modules/@fortawesome/fontawesome-free/js/all";

export default {};
</script>

<style>
@import "../node_modules/bootstrap/dist/css/bootstrap.css";
@import url("https://fonts.googleapis.com/css2?family=Sarabun&display=swap");

* {
  font-family: "Sarabun", sans-serif;
}

.btn-width100 {
  width: 100%;
}

.margin-top-100 {
  margin-top: 5rem;
  margin-bottom: 3rem;
}

.red_x {
    font-weight: 900;
    color: white;
    padding: 0px 4px;
    margin-left: 5px;
    margin-right: 5px;
}

.blue_o {
    font-weight: 900;
    color: white;
    padding: 0px 4px;
    margin-left: 10px;
    margin-right: 5px;
}

</style>
