import {
  createRouter,
  createWebHistory
} from "vue-router";
import jwt from "jsonwebtoken";
import store from "../store/index";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import Customer from "../views/Customer.vue";
import Policy from "../views/Policy.vue";
import Setting from "../views/Setting.vue";
import User from "../views/User.vue";
import PageNotFound from "../views/PageNotFound.vue";

import PointView from "../views/secret/PointView.vue";

import Note from "../views/stock/Note.vue";
import Report from "../views/stock/Report.vue";
import ReportXD from "../views/stock/ReportXD.vue";

import Stock from "../views/Stock.vue";

import Remember from "../views/remember/Remember.vue";
import Vocabulary from "../views/Vocabulary.vue";
import Sentence from "../views/Sentence.vue";

const routes = [{
    path: "/404",
    name: "PageNotFound",
    component: PageNotFound,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/",
    alias: "/home",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/customer",
    name: "Customer",
    component: Customer,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/stock",
    name: "Stock",
    component: Stock,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/stock/note",
    name: "Note",
    component: Note,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/stock/report",
    name: "Report",
    component: Report,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/stock/report/xd",
    name: "ReportXD",
    component: ReportXD,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/user",
    name: "User",
    component: User,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting",
    name: "Setting",
    component: Setting,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/remember",
    name: "Remember",
    component: Remember,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/vocabulary",
    name: "Vocabulary",
    component: Vocabulary,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sentence",
    name: "Sentence",
    component: Sentence,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/policy",
    name: "Policy",
    component: Policy,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/pointview",
    name: "PointView",
    component: PointView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (!to.matched.length) {
    next({
      name: "PageNotFound"
    });
  } else {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      const tokenId = localStorage.getItem("tokenId");

      if (tokenId) {
        const payload = jwt.decode(tokenId);

        store.commit("autoLogout");

        store.commit("set_login_email", payload.email);

        if (store.getters.get_auth.tier === 0) {
          await store.dispatch("authCheck");
        }

        switch (to.name) {
          case "Home": {
            if (store.getters.get_auth.tier >= store.getters.get_authorization.View.Home) {
              next();
            } else {
              next({
                name: "Login"
              });
            }
            break;
          }
          case "Customer": {
            if (store.getters.get_auth.tier >= store.getters.get_authorization.View.Customer) {
              next();
            } else {
              next({
                name: "Login"
              });
            }
            break;
          }
          case "Note": {
            if (store.getters.get_auth.tier >= store.getters.get_authorization.View.Stock) {
              next();
            } else {
              next({
                name: "Login"
              });
            }
            break;
          }
          case "Stock": {
            if (store.getters.get_auth.tier >= store.getters.get_authorization.View.Stock) {
              next();
            } else {
              next({
                name: "Login"
              });
            }
            break;
          }
          case "Report": {
            if (store.getters.get_auth.tier >= store.getters.get_authorization.View.Report) {
              next();
            } else {
              next({
                name: "Login"
              });
            }
            break;
          }
          case "ReportXD": {
            if (store.getters.get_auth.tier >= store.getters.get_authorization.View.ReportXD) {
              next();
            } else {
              next({
                name: "Login"
              });
            }
            break;
          }
          case "User": {
            if (store.getters.get_auth.tier >= store.getters.get_authorization.View.User) {
              next();
            } else {
              next({
                name: "Login"
              });
            }
            break;
          }
          case "Setting": {
            if (store.getters.get_auth.tier >= store.getters.get_authorization.View.Setting) {
              next();
            } else {
              next({
                name: "Login"
              });
            }
            break;
          }
          case "Remember": {
            if (store.getters.get_auth.tier >= store.getters.get_authorization.View.Remember) {
              next();
            } else {
              next({
                name: "Login"
              });
            }
            break;
          }
          case "Vocabulary": {
            if (store.getters.get_auth.tier >= store.getters.get_authorization.View.Remember) {
              next();
            } else {
              next({
                name: "Login"
              });
            }
            break;
          }
          case "Sentence": {
            if (store.getters.get_auth.tier >= store.getters.get_authorization.View.Remember) {
              next();
            } else {
              next({
                name: "Login"
              });
            }
            break;
          }
          default: {
            break;
          }
        }
      } else {
        next({
          name: "Login"
        });
      }
    } else {
      next();
    }
  }
});

export default router;