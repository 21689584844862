<template>
  <div>
    <Navbar />
    <div class="container-fluid">
      <div class="margin-top-100">
        <h1>404</h1>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "PageNotFound",
  components: {
    Navbar,
    Footer,
  },
};
</script>
