<template>
  <div class="container-fluid">
    <div class="row mt-4 justify-content-md-center">
      <div class="col-md-5">
        <img
          class="d-block mx-auto mb-4 mt-4"
          src="../assets/img/a-512x512.png"
          alt=""
          width="120"
          height="100"
        />
        <label for="inputUsername" class="mb-2"
          ><i class="fas fa-user"></i> : ชื่อผู้ใช้ หรือ อีเมล (Username or
          Email)</label
        >
        <input
          v-on:keyup.enter="loginCheck"
          v-model="username"
          type="email"
          ref="inputUsername"
          class="form-control mb-3"
          placeholder="Username or Email"
          required
        />
        <label for="inputPassword" class="mb-2"
          ><i class="fas fa-key"></i> : รหัสผ่าน (Password)</label
        >
        <input
          v-on:keyup.enter="loginCheck"
          v-model="password"
          type="password"
          ref="inputPassword"
          class="form-control mb-4"
          placeholder="Password"
          required
        />
        <div class="d-flex gap-3">
          <button
            @click="loginCheck"
            class="btn btn-primary btn-width100"
            type="button"
          >
            เข้าสู่ระบบ
          </button>
          <button
            @click="cancelButton"
            class="btn btn-danger btn-width100"
            type="button"
          >
            ยกเลิก
          </button>
        </div>
        <div class="mt-3">
          <button
            @click="register"
            class="btn btn-success btn-width100"
            type="button"
          >
            สมัครสมาชิก
          </button>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";

// import axios from "axios";

import Swal from "sweetalert2";

export default {
  components: {
    Footer,
  },
  data() {
    return {
      username: "",
      password: "",
    };
  },
  mounted() {
    this.$refs.inputUsername.focus();
    this.$store.commit("Logout");
  },
  methods: {
    async register() {
      this.$router.push({ name: "Register" });
    },
    async loginCheck() {
      if (this.username.trim() === "") {
        await Swal.fire({
          title: "พบข้อผิดพลาด",
          text: "กรุณากรอกชื่อผู้ใช้",
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });

        setTimeout(() => {
          this.$refs.inputUsername.focus();
        }, 300);
      } else if (this.password.trim() === "") {
        await Swal.fire({
          title: "พบข้อผิดพลาด",
          text: "กรุณากรอกพาสเวิร์ด",
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });

        setTimeout(() => {
          this.$refs.inputPassword.focus();
        }, 300);
      } else {
        // const res = await axios.post(
        //   `${process.env.VUE_APP_BACKEND}/mysqlapi/login`,
        //   {
        //     username: this.username,
        //     password: this.password,
        //   }
        // );
        this.$store.dispatch("login", {
          username: this.username,
          password: this.password,
        });

        // await Swal.fire({
        //   title: res.data.title,
        //   text: res.data.text,
        //   icon: res.data.icon,
        //   timer: res.data.timer
        // });

        // console.log(res.data.token);
      }
    },
    async cancelButton() {
      (this.username = ""), (this.password = "");
      this.$refs.inputUsername.focus();
    },
  },
};
</script>

<style scoped></style>
