// import moment from "moment";
import axios from "axios";
// import bcrypt from "bcryptjs";
import Swal from "sweetalert2";
// import router from "../router/index";

const setting = {
  state: {
    data: null,
    setting: {
      aim_address: {
        titlename: "", // คำนำหน้าชื่อ
        firstname: "", // ชื่อจริง
        lastname: "", // นามสกุล
        number: "", // เลขที่บ้าน
        detail: "", // ข้อมูลที่อยู่เพิ่มเติม หมู่ / ตรอก / ซอย / หมู่บ้าน
        road: "", // ถนน
        subdistrict: "", // ตำบล
        district: "", // อำเภอ
        province: "", // จังหวัด
        zipcode: "", // รหัสไปรษณีย์
      },
      aim_social: {
        workphone: "", // เบอร์โทรศัพท์ที่ทำงาน
        telephone: "", // เบอร์โทรศัพท์ที่บ้าน
        mobilephone: "", // โทรศัพท์มือถือ
        faxphone: "", // แฟกซ์
        facebook: "", // เฟสบุ๊คผู้สมัคร
        youtube: "", // ช่องยูทูป
        instagram: "", // อินตราแกรม
        twitter: "", // ทวิตเตอร์
        line: "", // ไลน์
      },
    },
  },
  mutations: {
    set_setting_state(state, data) {
      state.data = 1;
      state.setting.aim_address.titlename = data.aim_addresses[0].titlename;
      state.setting.aim_address.firstname = data.aim_addresses[0].firstname;
      state.setting.aim_address.lastname = data.aim_addresses[0].lastname;
      state.setting.aim_address.number = data.aim_addresses[0].number;
      state.setting.aim_address.detail = data.aim_addresses[0].detail;
      state.setting.aim_address.road = data.aim_addresses[0].road;
      state.setting.aim_address.subdistrict = data.aim_addresses[0].subdistrict;
      state.setting.aim_address.district = data.aim_addresses[0].district;
      state.setting.aim_address.province = data.aim_addresses[0].province;
      state.setting.aim_address.zipcode = data.aim_addresses[0].zipcode;

      state.setting.aim_social.workphone = data.aim_socials[0].workphone;
      state.setting.aim_social.telephone = data.aim_socials[0].telephone;
      state.setting.aim_social.mobilephone = data.aim_socials[0].mobilephone;
      state.setting.aim_social.faxphone = data.aim_socials[0].faxphone;
      state.setting.aim_social.facebook = data.aim_socials[0].facebook;
      state.setting.aim_social.youtube = data.aim_socials[0].youtube;
      state.setting.aim_social.instagram = data.aim_socials[0].instagram;
      state.setting.aim_social.twitter = data.aim_socials[0].twitter;
      state.setting.aim_social.line = data.aim_socials[0].line;
      Swal.close();
    },
  },
  actions: {
    async EditSetting({ commit }) {
      const token = localStorage.getItem("tokenId");

      Swal.fire({
        allowEscapeKey: true,
        showConfirmButton: false,
        allowOutsideClick: false,
        title: "Loading.....",
        text: "กรุณารอสักครู่",
      });

      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BACKEND}/mysqlapi/setting`,
          {
            token: token,
          }
        );

        commit("set_setting_state", res.data[0]);
      } catch (error) {
        console.log(error);
      }
    },
    async Edit({ state }) {
      const token = localStorage.getItem("tokenId");

      Swal.fire({
        allowEscapeKey: true,
        showConfirmButton: false,
        allowOutsideClick: false,
        title: "Loading.....",
        text: "กรุณารอสักครู่",
      });

      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BACKEND}/mysqlapi/editsetting`,
          {
            token: token,
            data: state.setting
          }
        );

        Swal.fire({
          title: res.data.title,
          text: res.data.text,
          icon: res.data.icon,
          showConfirmButton: false,
          timer: res.data.timer,
        });

        // console.log(res);
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {
    get_setting_state: (state) => {
      return state;
    },
  },
};

export default setting;
