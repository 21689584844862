import { createStore } from "vuex";
import register from "./register";
import login from "./login";
import user from "./user";
import authorization from "./authorization";
import setting from "./setting";
import stock from "./stock";
import remember from "./remember";
import vocabulary from "./vocabulary";
import sentence from "./sentence";

import axios from "axios";
import Swal from "sweetalert2";
import jwt from "jsonwebtoken";
import route from "../router/index";

export default createStore({
  state: {
    logoutTime: 0,
    auth: {
      tier: 0,
      active: 0,
    },
  },
  mutations: {
    Logout(state) {
      localStorage.removeItem("tokenId");

      state.auth = {
        tier: 0,
        active: 0,
      };

      user.state.data = null;

      login.state.email = null;

      register.state.register = {
        email: "",
        password: "",
        passwordconfirm: "",
        username: "",
      };

      setting.state.data = null;

      setting.state.setting = {
        aim_address: {
          titlename: "", // คำนำหน้าชื่อ
          firstname: "", // ชื่อจริง
          lastname: "", // นามสกุล
          number: "", // เลขที่บ้าน
          detail: "", // ข้อมูลที่อยู่เพิ่มเติม หมู่ / ตรอก / ซอย / หมู่บ้าน
          road: "", // ถนน
          subdistrict: "", // ตำบล
          district: "", // อำเภอ
          province: "", // จังหวัด
          zipcode: "", // รหัสไปรษณีย์
        },
        aim_social: {
          workphone: "", // เบอร์โทรศัพท์ที่ทำงาน
          telephone: "", // เบอร์โทรศัพท์ที่บ้าน
          mobilephone: "", // โทรศัพท์มือถือ
          faxphone: "", // แฟกซ์
          facebook: "", // เฟสบุ๊คผู้สมัคร
          youtube: "", // ช่องยูทูป
          instagram: "", // อินตราแกรม
          twitter: "", // ทวิตเตอร์
          line: "", // ไลน์
        },
      },

      clearTimeout(state.logoutTime);
    },
    autoLogout(state) {
      const tokenId = localStorage.getItem("tokenId");
      const payload = jwt.decode(tokenId);
      const Now = new Date();
      const EndTime = new Date(payload.exp * 1000);

      if (state.logoutTime != 0) {
        clearTimeout(state.logoutTime);
      }

      state.logoutTime = setTimeout(() => {
        // console.log("autologout");
        route.push({ name: "Login" });
        Swal.fire({
          title: "หมดเวลา",
          text: "กรุณาเข้าระบบใหม่อีกครั้ง",
          icon: "warning",
          showConfirmButton: false,
          timer: 3000,
        });
      }, EndTime.getTime() - Now.getTime());
    },
    set_auth(state, data) {
      state.auth = data;
    },
  },
  actions: {
    async authCheck({ commit }) {
      const token = localStorage.getItem("tokenId");
      const res = await axios.post(
        `${process.env.VUE_APP_BACKEND}/mysqlapi/status`,
        {
          token: token,
        }
      );
      commit("set_auth", res.data);
    },
  },
  getters: {
    get_auth(state) {
      return state.auth;
    },
    emailCheck: () => (email) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    alertSystem: () => (data) => {
      Swal.fire({
        title: data.title,
        text: data.text,
        icon: data.icon,
        showConfirmButton: false,
        timer: data.timer,
      });
    },
  },
  modules: {
    register: register,
    login: login,
    user: user,
    authorization: authorization,
    setting: setting,
    stock: stock,
    remember: remember,
    vocabulary: vocabulary,
    sentence: sentence
  },
});
