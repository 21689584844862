import axios from "axios";
import Swal from "sweetalert2";

const remember = {
  state: {
    remember_data: null,
    remember_add: null,
    remember_hint: null
  },
  mutations: {
    set_remember_data(state, payload) {
      // console.log(payload)
      state.remember_data = payload;
    },
    set_remember_add(state, payload) {
      // console.log(payload)
      state.remember_add = payload;
    },
    set_remember_hint(state, payload) {
      // console.log(payload)
      state.remember_hint = payload;
    },
  },
  actions: {
    load_remember_data({
      commit
    }) {
      // console.log("Load Remember");
      axios
        .get(`${process.env.VUE_APP_BACKEND}/mongoapi/remember/remember`)
        .then(function (data) {
          // console.log(data);
          commit("set_remember_data", data.data);
        })
        .catch(function (err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_remember_save_data({
      dispatch
    }, data) {
      axios
        .post(`${process.env.VUE_APP_BACKEND}/mongoapi/remember/remember`, data)
        .then(function () {
          // console.log(data);
          Swal.fire("บันทึกข้อมูลเรียบร้อย", "โปรดเช็คข้อมูล", "success");
        })
        .then(() => {
          dispatch("load_remember_data");
        })
        .catch(function (err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_remember_del_data({
      dispatch
    }, id) {
      axios
        .delete(`${process.env.VUE_APP_BACKEND}/mongoapi/remember/remember/${id}`)
        .then(function () {
          // console.log(data);
          Swal.fire("ลบเรียบร้อย", "ลบข้อมูลเรียบร้อย", "success");
          dispatch("load_remember_data");
        })
        .catch(function (err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_remember_add_data({
      dispatch
    }, data) {
      axios
        .post(`${process.env.VUE_APP_BACKEND}/mongoapi/remember/remember/add`, data)
        .then(() => {
          dispatch("load_remember_data");
        })
        .catch(function (err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_remember_del_detail_data({
      dispatch
    }, id) {
      // console.log(id);
      axios
        .delete(`${process.env.VUE_APP_BACKEND}/mongoapi/remember/remember/detail/${id[0]}/${id[1]}`)
        .then(function () {
          // console.log(data);
          // Swal.fire("ลบเรียบร้อย", "ลบข้อมูลเรียบร้อย", "success");
          dispatch("load_remember_data");
        })
        .catch(function (err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_remember_c_detail_data({
      commit
    }, id) {
      // console.log(id);
      axios
        .post(`${process.env.VUE_APP_BACKEND}/mongoapi/remember/remember/detail/change/${id[0]}/${id[1]}`)
        .then(function (data) {
          // console.log(data.data.d);
          commit("set_remember_add", data.data.d);
          commit("set_remember_hint", data.data.h);
          // dispatch("load_remember_data");
        })
        .catch(function (err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_remember_c_update_detail_data({
      dispatch
    }, data){
      // console.log(id);
      axios
        .post(`${process.env.VUE_APP_BACKEND}/mongoapi/remember/remember/detail/update`, [ data[0], data[1], data[2], data[3] ])
        .then(function () {
          // console.log(data);

          dispatch("load_remember_data");
        })
        .catch(function (err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_remember_c_update_header_detail_data({
      dispatch
    }, data){
      // console.log(id);
      axios
        .post(`${process.env.VUE_APP_BACKEND}/mongoapi/remember/remember/detail/update/header`, [ data[0], data[1] ])
        .then(function () {
          // console.log(data);

          dispatch("load_remember_data");
        })
        .catch(function (err) {
          // Crawling failed...
          console.log(err);
        });
    },
    load_remember_detail_show_data({
      dispatch
    }, id) {
      // console.log(id);
      axios
        .post(`${process.env.VUE_APP_BACKEND}/mongoapi/remember/remember/detail/show`, [id[0], id[1], id[2]])
        .then(function () {
          // console.log(data);
          dispatch("load_remember_data");
        })
        .catch(function (err) {
          // Crawling failed...
          console.log(err);
        });
    }
  },
  getters: {
    get_remember_data: (state) => {
      return state.remember_data;
    },
    get_remember_add: (state) => {
      return state.remember_add;
    },
    get_remember_hint: (state) => {
      return state.remember_hint;
    },
  },
  modules: {},
};

export default remember;